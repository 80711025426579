// CrewEvaluatorRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";

import AssessmentList from "./pages/AssessmentList";
import CreateAssessment from "./pages/CreateAssessment";
import GenerateAssessment from "./pages/GenerateAssessment";
import ListOfConcepts from "./pages/ListOfConcepts";
import CreateConcept from "./pages/CreateConcept";

const CrewEvaluatorRoutes = () => {
  return (
    <Routes>
      <Route
        path="/assessmentList"
        Component={AssessmentList}
      />
       <Route
        path="/createAssessment"
        Component={CreateAssessment}
      />
       <Route
        path="/generateAssessment"
        Component={GenerateAssessment}
      />
       <Route
        path="/listOfConcepts"
        Component={ListOfConcepts}
      />
       <Route
        path="/createConcept"
        Component={CreateConcept}
      />
      
      

    </Routes>
  );
};

export default CrewEvaluatorRoutes;

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import candidateService from '../../service/candidateService';
import AILoading from '../../../../components/AILoading';
import { toast } from 'react-toastify';

// Import your candidate service

const UploadResumeWithDropzone = ({setSelectedCandidate}) => {
  const { getAccessTokenSilently,user } = useAuth0();
  const [fileSelected,setFileSelected]= useState(false);
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
     
      toast.error('Resume is missing. Please drop a resume file.', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
       });
      return;
    }

    const file = acceptedFiles[0]; // Assume only one file is dropped
    console.log(file.name);
    const acceptedFileTypes = ['pdf','docx']
    const ext =  file.name.split('.').pop();
    console.log(ext);
    if(acceptedFileTypes.indexOf(ext) === -1){
      
      toast.error('Please drop a resume in PDF or docx format.', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
       });
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      setFileSelected(true)
     const response =await candidateService.uploadResume(file,user.email);
     // console.log(response)
      setSelectedCandidate(response)
      
      toast.success( 'WeEvaluate successfully extracted candidate  details from '+file.name, {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFileSelected(false)
      // You can add further logic or navigate to another page upon successful upload
    } catch (error) {
      console.log(error)
     if(error.status === 409 || error.status === 400){ 
      toast.error(error.response.body.message, {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
       });
      setFileSelected(false)
    } 
     else {    
      
      toast.error('Error uploading resume', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
       });
     setFileSelected(false)
    }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className=" mb-6 w-full"> {/* Added mb-10 class */}
   {!fileSelected && <div
      {...getRootProps()}
      className={`dropzone bg-white border border-gray-300 rounded-lg shadow-md p-10 ${
        isDragActive ? 'bg-green' : 'bg-red'
      }`}
    >
      <input {...getInputProps()} />
     <p style={{fontWeight:'bold',textAlign:'center'}}>Drag candidate's resume file here or click to select.</p>
        </div>}
    {fileSelected &&  (<AILoading text="WeEvaluate AI is Analysis Candidate's resume" type="model"/>)}
  </div>
  );
};

export default UploadResumeWithDropzone;

import React, { useState } from 'react';

function SkillsTagInput({ value, onChange }) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      onChange([...value, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveTag = (indexToRemove) => {
    const updatedSkills = value.filter((_, index) => index !== indexToRemove);
    onChange(updatedSkills);
  };

  return (
<div className="flex flex-wrap items-center gap-2">
  {value.map((tag, index) => (
    <div key={index} className="bg-blue-500 text-white rounded-full px-3 py-1 flex items-center mb-2">
      <span>{tag}</span>
      <button
        onClick={() => handleRemoveTag(index)}
        className="ml-2 text-white focus:outline-none"
      >
        x
      </button>
    </div>
  ))}

  <div className="w-full mt-2">
    <input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="w-full outline-none border border-gray-300 py-1 px-3 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:bg-white"
      placeholder="Enter skills (Press Enter to add)"
    />
  </div>
</div>


  );
}

export default SkillsTagInput;

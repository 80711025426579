import React from 'react';
 // Assuming you've created this based on previous steps
import AdminPanel from './AdminPanel';

const HomeScreen = (sidebar) => {
  console.log('sidebar',sidebar.sidebar)
  return (
    <div>
      <AdminPanel sidebar={sidebar.sidebar}/>
    </div>
  );
};

export default HomeScreen;

import React, { useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

function InlineButtonControl({saveClick,editClick}) {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditing(true);
    editClick()
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    setIsEditing(false);
    saveClick()
  };

  return (
    <div className="flex items-center space-x-4">

      <FaPencilAlt onClick={handleEditClick} className={`flex items-center justify-center mb-2  text-blue-500 w-6 h-6 cursor-pointer ${isEditing ? 'hidden' : ''}`} />

      <FontAwesomeIcon icon={faSave} onClick={handleSaveClick} className={`flex items-center justify-center   text-green-500 w-6 h-6 cursor-pointer ${isEditing ? '' : 'hidden'}`}/>
    </div>
  );
}

export default InlineButtonControl;

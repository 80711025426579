import React, { useState } from "react";
import axios from "axios";

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import LogoutButton from "../../components/LogoutButton";
import userService from "../../services/userService";
import superagent from "../../services/weevaluateagent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
const UserProfileForm = ({ companyStatus, profile,callbackCompanyProfileAvailable }) => {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const [isCompanyProfileAvailable, setCompanyProfileAvailable] =
    useState(false); // Add state for company profile availability
  const [isEditMode, setIsEditMode] = useState(false); // State to track edit mode
  const [userData, setUserData] = useState(null);
  const [acknowledge,setAcknowledge] = useState(null);
  const fullName = user.name;
  const spaceIndex = fullName.indexOf(" ");
  const [code, setCode] = useState('');
  const [verified, setVerified] = useState(false);
  const firstName =
    spaceIndex !== -1 ? fullName.substring(0, spaceIndex) : fullName;
  const lastName = spaceIndex !== -1 ? fullName.substring(spaceIndex + 1) : "";



  const initialFormData = {
    firstName: user ? user.given_name : "",
    lastName: user ? user.family_name : "",
    email: user ? user.email : "",
    companyName: "",
    jobTitle: "",
    contactNumber: "",
    profilePicture: "",
    teamRole: "HR Manager",
    billingInformation: "",
    preferences: "",
    location:"",
    companyEmail:"",
    shortName: "", // New field for the short name
    shortNameAvailable: null, // State to store the availability status of the short name
    user:user
  };

  const [formData, setFormData] = useState(initialFormData);
 /* if(profile){
    setFormData(profile)
  }*/

  //console.log('formData',formData)
  React.useEffect(() => {
    console.log(user);
    if (profile) {
      setFormData(profile.users[0]);
      setStatus(companyStatus);
      setCompanyProfileAvailable(true);
    } else if (!companyStatus && !profile) {
      console.log("I am comin here");
      getcompanyProfile(user.email);
    }
  }, [profile, companyStatus]);
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const getcompanyProfile = async (email) => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await userService.checkCompanyProfileEmail(email);
      const data = await response;
      console.log("data in userprofile", data);
      //console.log('nvjbkhaas',data.companyName)
      if (data.isClientAvailable) {
        setFormData(data.client.users[0]);
        setStatus(data.isComapnyActive);
        setCompanyProfileAvailable(true);
       }
    } catch (error) {
      console.error("Error checking short name availability:", error);
    }
  };
  const confirmClientCodeByEmail = async () => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await userService.confirmClientCodeByEmail(code);
      const data = await response;
      console.log("data in userprofile", data);
      //console.log('nvjbkhaas',data.companyName)
      if (data.isClientAvailable&& data.isComapnyActive) {
        setFormData(data.client.users[0]);
        setStatus(data.isComapnyActive);
        setCompanyProfileAvailable(true);
        callbackCompanyProfileAvailable(user.email)
        setVerified(true);
      }else{
        setVerified(false);
      }
    } catch (error) {
      console.error("Error checking short name availability:", error);
    }
  };

  const handleEdit = async () => {
    try {
      const userId = profile ? profile.users[0]._id : user.sub;
      console.log("userId in handle edit", userId);
      await getUserById(userId);
      setIsEditMode(true);
    } catch (error) {
      console.error("Error fetching user profile data:", error);
    }
  };

  const getUserById = async (userId) => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await userService.getUserById(userId);
      const userdata = await response;
      console.log("getUserById data", userdata);
      if (userdata) {
        setUserData(userdata);
        setStatus(companyStatus);
        setCompanyProfileAvailable(true);
      }
    } catch (error) {
      console.error("Error fetching user profile data by ID:", error);
    }
  };

  const handleCheckAvailability = async () => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await userService.CheckShortNameAvailability(
        formData.shortName
      );
      console.log("data check availability", response);
      setFormData({ ...formData, shortNameAvailable: response.available });
    } catch (error) {
      console.error("Error checking short name availability:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Update status to 'active' before sending the request
      formData.user=user;
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await userService.createUser(formData);

      //console.log(response);
      if (response) {
        console.log("User profile saved successfully", response);
        setCompanyProfileAvailable(true);
        setStatus(false);
        // Optionally, you can reset the form here
      } else {
        console.error("Failed to save user profile");
      }
    } catch (err) {
      console.error("Error saving user profile:", err);
      if (err.status === 400 && err.response && err.response.body && err.response.body.error) {
        // If the error is a 400 Bad Request and contains an error message
        const errorMessage = err.response.body.error;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          width:'300px'
         });
      } 
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const profileId = profile.users[0]._id;
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await userService.updateUserById(profileId, formData);
      if (response) {
        console.log("User profile updated successfully", response);
      } else {
        console.error("Failed to update user profile");
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  const handleAcknowledgeChange = (event) => {
    setAcknowledge(event.target.checked); // Update the acknowledge state based on the checkbox status
  };
  return (
    <>

      {/* ChatGPT Code */}
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {isCompanyProfileAvailable && !status && (
     <div className="mb-4 block text-gray-700 text-sm mb-2 font-bold center">
     <p className="text-lg font-semibold text-center text-gray-900 mb-2">Welcome aboard!</p>
     <p className="text-center mb-2">Your profile has been successfully created and is now under review.</p>
     <p className="text-center mb-2">Please remain patient! Once your request is approved, you'll receive an email notification to your company email containing a verification code. <br></br>Simply enter this code to gain full access.</p>
   </div>
        )}
         {/* Add other rows with grid layout */}
         {isCompanyProfileAvailable && !status && (
         <div className="max-w-2xl mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
       
        
        <div>
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="verificationCode"
        >
          Verification Code
        </label>
        <div className="flex items-center">
          <input
            className="shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
            id="verificationCode"
            type="text"
            name="verificationCode"
            value={code}
           onChange={handleCodeChange}
           
          />
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={()=>confirmClientCodeByEmail()}
          >
            VerifyCode
          </button>
        
        </div>
      </div>
      

        
       
        </div> )}
        {status && (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleEdit}
          >
            Edit Profile
          </button>
        )}

        {/* First Row */}
        <div className="mb-4 block text-gray-700 text-sm mb-2">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="companyName"
          >
            Organization Name
          </label>
          {!isCompanyProfileAvailable || isEditMode ? (
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="companyName"
              type="text"
              placeholder="Company Name"
              name="companyName"
              value={isEditMode ? userData.companyName : formData.companyName}
              onChange={handleChange}
              disabled={isEditMode} // Disable the input when isEditMode is true
            />
          ) : (
            <p> {formData.companyName}</p>
          )}
        </div>

        {/* Remaining Rows */}
        <div className="grid grid-cols-2 gap-4">
          {/* First Column */}
          <div>
            {/*  Org Code Field */}
            <div className="mb-4 block text-gray-700 text-sm mb-2">
  <label
    className="block text-gray-700 text-sm font-bold mb-2"
    htmlFor="shortName"
  >
    Org Code
  </label>
  <div className="flex">
    {!isCompanyProfileAvailable || isEditMode ? (
      <div>
        <input
          className="shadow appearance-none border rounded w-20 py-2 px-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="shortName"
          type="text"
          placeholder="Short Name"
          name="shortName"
          value={isEditMode ? userData.shortName : formData.shortName}
          onChange={handleChange}
          disabled={isEditMode} // Disable the input when isEditMode is true
        />
        {!isEditMode && ( // Render the button only when isEditMode is false
          <div style={{ display: 'inline-block' }}>
            <button
              className="w-30 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleCheckAvailability}
            >
              Check Availability
            </button>
            {formData.shortNameAvailable !== null && (
              <p
                className={formData.shortNameAvailable ? "text-green-500" : "text-red-500"}
                style={{ display: 'inline-block', marginLeft: '4px', verticalAlign: 'middle' }}
              >
                {formData.shortNameAvailable ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <FontAwesomeIcon icon={faXmark} />
                )}
              </p>
            )}
          </div>
        )}
      </div>
    ) : (
      <p> {formData.shortName}</p>
    )}
  </div>
</div>


            {/*  First Name Field */}
            <div className="mb-4 block text-gray-700 text-sm mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="firstName"
              >
                First Name
              </label>
              {!isCompanyProfileAvailable || isEditMode ? (
                <input
                  className="shadow appearance-none border rounded bg-black-200 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={isEditMode ? userData.firstName : formData.firstName}
                  onChange={handleChange}
                />
              ) : (
                <p> {isEditMode ? userData.firstName : formData.firstName}</p>
              )}
            </div>
            {/* Contact Number*/}
            <div className="mb-4 block text-gray-700 text-sm mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="contactNumber"
              >
                Contact Number
              </label>
              {!isCompanyProfileAvailable || isEditMode ? (
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="contactNumber"
                  type="tel"
                  placeholder="Contact Number"
                  name="contactNumber"
                  value={
                    isEditMode ? userData.contactNumber : formData.contactNumber
                  }
                  onChange={handleChange}
                />
              ) : (
                <p> {formData.contactNumber}</p>
              )}
            </div>

                 {/* companyEmail*/}
            <div className="mb-4 block text-gray-700 text-sm mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="companyEmail"
              >
               Company Email
              </label>
              {!isCompanyProfileAvailable || isEditMode ? (
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="companyEmail"
                  type="email"
                  placeholder="Company Email"
                  name="companyEmail"
                  value={
                    isEditMode ? userData.companyEmail : formData.companyEmail
                  }
                  onChange={handleChange}
                />
              ) : (
                <p> {formData.companyEmail}</p>
              )}
            </div>

            
            {/* inEditMode Preferences*/ }
            {isEditMode && (
              <div className="mb-4 block text-gray-700 text-sm mb-2">
                {!isCompanyProfileAvailable || isEditMode ? (
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="preferences"
                    >
                      Preferences
                    </label>

                    <textarea
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="preferences"
                      placeholder="Preferences"
                      name="preferences"
                      value={
                        isEditMode ? userData.preferences : formData.preferences
                      }
                      onChange={handleChange}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
             {/* inEditMode Billing Information*/ }
{isEditMode && (
              <div className="mb-4 block text-gray-700 text-sm mb-2">
                {!isCompanyProfileAvailable || isEditMode ? (
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="billingInformation"
                    >
                      Billing Information
                    </label>

                    <textarea
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="billingInformation"
                      placeholder="Billing Information"
                      name="billingInformation"
                      value={
                        isEditMode
                          ? userData.billingInformation
                          : formData.billingInformation
                      }
                      onChange={handleChange}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>

          {/* Second Column */}
          <div>
            {/* Email Address Field */}
            <div className="mb-4 block text-gray-700 text-sm mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email Address
              </label>
              {!isCompanyProfileAvailable || isEditMode ? (
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder={user.email}
                  name="email"
                  value={isEditMode ? userData.email : formData.email}
                  onChange={handleChange}
                  disabled // Add the disabled attribute
                />
              ) : (
                <p>{user.email}</p>
              )}
            </div>

            {/* Last Name Field */}
            <div className="mb-4 block text-gray-700 text-sm mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="lastName"
              >
                Last Name
              </label>
              {!isCompanyProfileAvailable || isEditMode ? (
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={isEditMode ? userData.lastName : formData.lastName}
                  onChange={handleChange}
                />
              ) : (
                <p> {formData.lastName}</p>
              )}
            </div>
            {/* Role Field */}
            <div className="mb-4 block text-gray-700 text-sm mb-2">
           
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="teamRole"
                  >
                    Role
                  </label>
                  {!isCompanyProfileAvailable || isEditMode ? (
                  <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="teamRole"
                    name="teamRole"
                    value={isEditMode ? userData.teamRole : formData.teamRole}
                    onChange={handleChange}
                  >
                    <option value="">Select Role</option>
                    <option value="HR Director">HR Director</option>
                    <option value="HR Manager">HR Manager</option>
                    <option value="Director">Director</option>
                    <option value="Vice President (VP)">
                      Vice President (VP)
                    </option>
                    <option value="Manager">Manager</option>
                  </select>
               
              ) : (
                <p> {formData.teamRole}</p>
              )}
               </div>
            </div>
             {/* Last Name Field */}
            <div className="mb-4 block text-gray-700 text-sm mb-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="lastName"
              >
               Location
              </label>
              {!isCompanyProfileAvailable || isEditMode ? (
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="lastName"
                  type="text"
                  placeholder="Location"
                  name="location"
                  value={isEditMode ? userData.location : formData.location}
                  onChange={handleChange}
                />
              ) : (
                <p> {formData.location}</p>
              )}
            </div>   
            {isEditMode && (
              <div className="mb-4 block text-gray-700 text-sm mb-2">
                {!isCompanyProfileAvailable || isEditMode ? (
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="profilePicture"
                    >
                      Profile Picture
                    </label>

                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="profilePicture"
                      type="file"
                      name="profilePicture"
                      onChange={handleChange}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}


          </div>
        </div>

       
        {/* Button  Code */}

        <div className="flex items-center justify-center mt-6">
  {!isCompanyProfileAvailable || isEditMode ? (
    <div>
      {isEditMode ? (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
          onClick={handleUpdate}
        >
          Update Profile
        </button>
      ) : (
        <div>
          <div className="mb-2">
            <input
              type="checkbox"
              id="acknowledge"
              onChange={handleAcknowledgeChange}
              checked={acknowledge}
              className="mr-2"
            />
            <label htmlFor="acknowledge" className="text-sm text-gray-700">I acknowledge and agree to create this company profile.</label>
          </div>
         <center> <button
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${!acknowledge ? 'opacity-50 cursor-not-allowed' : ''}`}
            type="submit"
            onClick={handleSubmit}
            disabled={!acknowledge}
          >
            Create Profile
          </button></center>
        </div>
      )}
    </div>
  ) : (
    <></>
  )}
</div>


      </div>
    </>
  );
};

export default UserProfileForm;

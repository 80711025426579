import React from 'react';

function InterviewTable() {
  const interviewData = [
    { name: 'John Doe', position: 'Software Engineer', date: '2024-04-15', actions: 'View' },
    // Add more interview data
  ];

  return (
    <div className="mt-10 bg-white p-6 rounded-lg shadow">
      <h2 className="text-2xl font-semibold mb-4">Live Interviews</h2>
      <div className="overflow-x-auto">
         <table   className="table-auto w-full">
          <thead>
            <tr>
              <td className="pb-4 font-semibold">Candidate Name</td>
              <td className="pb-4 font-semibold">Position</td>
              <td className="pb-4 font-semibold">Interview Started</td>
              <td className="pb-4 font-semibold"></td>
            </tr>
          </thead>
          <tbody>
            {interviewData.map((interview) => (
              <tr key={interview.name}> {/* Assuming name is a unique identifier */}
                <td className="pb-4">{interview.name}</td>
                <td className="pb-4">{interview.position}</td>
                <td className="pb-4">{interview.date}</td>
                <td className="pb-4"><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Join</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InterviewTable;

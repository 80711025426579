import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import apiUrls from "../../../utils/apiUrls";

const superagent = superagentPromise(_superagent, global.Promise);
const environment = process.env.REACT_APP_ENVIRONMENT;



// Get the API root
const hostname = window.location.hostname;
const API_ROOT = apiUrls[hostname] || "https://api.partner.weevaluate.ai/";

const encode = encodeURIComponent;
const responseBody = (res) => res.body;

let token = null;
const tokenPlugin = (req) => {
  if (token) {
    console.log(token)
    req.set("authorization", `Bearer ${token}`);
  }
};

const requestsA = {
    del: (url) =>
      superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
    get: async (url) => {
      console.log(url);
      try {
        const res = await superagent
          .get(`${API_ROOT}${url}`)
          .use(tokenPlugin)
          .then(responseBody);
        return res;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    put: (url, body) =>
      superagent
        .put(`${API_ROOT}${url}`, body)
        .use(tokenPlugin)
        .then(responseBody),
    post: async (url, body) => {
      console.log(body);
  
      try {
        const res = await superagent
          .post(`${API_ROOT}${url}`, body)
          .use(tokenPlugin)
          .then(responseBody);
        return res;
      } catch (err) {
      //  console.log("error in status ",err.status);
      //  console.log("error in response",err.message);
        throw err;
      }
    },
  };
  const reqABC = () => {
    return requestsA
  };
export default {
  superagent,
  requestsA,
  API_ROOT,
  encode,
  responseBody,
  tokenPlugin,
  setToken: (_token) => {
    token = _token;
  },
  reqA: () => {
    return requestsA
  },
};

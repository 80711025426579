import React from 'react';
import { PlusSmIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import InlineButtonControl from './InlineButtonControl';
const Skills = ({ skills, isExisted, isEditing, isSkillDetailsEdit, handleChange, deleteSkills, addSkills, skillDetailsSave, skillDetailsEdit }) => {
    
 
    return (
    <div>
      <div className="flex justify-end items-center mt-2">
  {isEditing && (
        <div className="cursor-pointer" onClick={addSkills}>
          <PlusSmIcon className="w-8 h-8 text-blue-500 mr-2" />
        </div>
      )}
    <div>
      
      {isExisted && <InlineButtonControl saveClick={skillDetailsSave} editClick={skillDetailsEdit} />}
    </div>
  </div>
  <div>
  {skills.map((skl, index) => (
    <div key={index} className="mb-4 flex flex-row  rounded py-2 px-4">
      <div className="grid grid-cols-2 gap-4 w-full">
        <div >
          {index===0 && (<label htmlFor={`skill_${index}`} className="block text-gray-700 font-bold mb-2 leftside_text">Skill</label>)}
          
        
          {!isExisted || isSkillDetailsEdit ? (
            <input 
              type="text" 
              id={`skill_${index}`}
              name={`skill_${index}`}
              value={skl.skill}
              onChange={(e) => handleChange(e, index)} 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          ) : (
            <div className='data-value'>{skl.skill}</div>
          )}
        </div>
        <div>
          {index===0 && (<label htmlFor={`availability_${index}`} className="block text-gray-700 font-bold mb-2 leftside_text">Years of Experience</label>)}
          
          {!isExisted || isSkillDetailsEdit ? (
            <input 
              type="text" 
              id={`availability_${index}`}
              name={`availability_${index}`}
              value={skl.availability}
              onChange={(e) => handleChange(e, index)} 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          ) : (
            <div className='data-value'>{skl.availability}</div>
          )}
        </div>
      </div>
      
      <div className='ml-3'>
      {index===0 && (<label htmlFor={`availability_${index}`} className="block text-gray-700 font-bold mb-2 leftside_text">&nbsp;</label>)}
        {isExisted && isSkillDetailsEdit && (
          <FontAwesomeIcon icon={faTrash} onClick={() => deleteSkills(index)} className="text-red-500" />
        )}
      </div>
    </div>
  ))}
</div>
    </div>
  );
};

export default Skills;

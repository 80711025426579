import React, { useState } from 'react';
import candidateService from '../services/candidateService';

const UpdateStatusForm = ({ candidate, entryId, onStatusUpdate, onClose }) => {
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleStatusUpdateClick = async () => {
    try {
      const currentDate = new Date();
      const dateString = currentDate.toString();
  
      candidate.statusUpdates.push({
        status: selectedStatus,
        updatedDate: dateString
      });

      // Update candidate status
      const response = await candidateService.updateCandidateById(entryId, candidate);
      onStatusUpdate(entryId, selectedStatus);
      console.log('Candidate Status updated:', response.data);
      onClose();
    } catch (error) {
      console.error('Error updating status:', error);
      // Optionally, show an error message to the user
      // You can set a state variable to show the error message in the UI
    }
  };

  return (
    <div>
      <select
        id="status"
        className="block w-full p-2 border border-gray-300 rounded-md text-blue-800"
        value={selectedStatus}
        onChange={handleStatusChange}
      >
        <option value="" disabled>Select Status</option>
        <option value="Received">Received</option>
        <option value="Under Review">Under Review</option>
        <option value="In Progress">In Progress</option>
        <option value="Interview Scheduled">Interview Scheduled</option>
        <option value="Interviewed">Interviewed</option>
        <option value="Pending Decision">Pending Decision</option>
        <option value="Offer Extended">Offer Extended</option>
        <option value="Offer Accepted">Offer Accepted</option>
        <option value="Rejected">Rejected</option>
        {/* Add more status options if needed */}
      </select>
      <div className="mt-4">
        <button onClick={handleStatusUpdateClick} className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-blue-700">Save</button>
        <button onClick={onClose} className="ml-2 px-4 py-2 bg-gray-800 text-white rounded hover:bg-green-700">Close</button>
      </div>
    </div>
  );
};

export default UpdateStatusForm;

// InterviewGeniusRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import DisplayAllScheduledInterview from "./components/DisplayAllScheduledInterview/DisplayAllScheduledInterview";
import SelectCandidate from "./components/SelectCandidate/SelectCandidate";
import CandidateAnalysis from "./components/CandidateAnalysis/CandidateAnalysis";
import GeneratedQuestions from "./components/GeneratedQuestions/GeneratedQuestions";
import CandidateReport from "./components/CandidateReport/CandidateReport";
import AnalysisList from "./components/CandidateAnalysis/AnalysisList";
import InterviewTracker from "./components/DisplayAllScheduledInterview/InterviewTracker";
import InterviewReport from "./components/DisplayAllScheduledInterview/InterviewReport";
import InterviewFinalReport from "./components/DisplayAllScheduledInterview/InterviewFinalReport";

const InterviewGeniusRoutes = () => {
  return (
    <Routes>
      <Route
        path="/scheduleinterviews"
        Component={DisplayAllScheduledInterview}
      />
      <Route path="/analysisList" Component={AnalysisList} />
      <Route path="/selectcandidate" Component={SelectCandidate} />
      <Route
        path="/analysis/:jobId/:candidateId"
        Component={CandidateAnalysis}
      />
      <Route
        path="/questions/:jobId/:candidateId"
        Component={GeneratedQuestions}
      />
      
      <Route path="/tracker/:jobId/:candidateId" Component={InterviewTracker} />
      <Route path="/report/:jobId/:candidateId" Component={InterviewReport} />
      <Route path="/finalreport/:jobId/:candidateId" Component={InterviewFinalReport} />

    </Routes>
  );
};

export default InterviewGeniusRoutes;

// Auth0Provider.js
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from './config';

const config = getConfig();
const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,

};
const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <Auth0Provider
    {...providerConfig}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

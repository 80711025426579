import axios from 'axios';
import weevaluateagent from './weevaluateagent';

const API_BASE_URL = '/ats/v1'; // Replace with your API base URL

const candidateService = {
  uploadCandidateDescriptionPDF: async (file,user) => {
    try {
      const formData = new FormData();
      formData.append('pdfFile', file);
      formData.append('user',user);

      const response = await weevaluateagent.requestsA.post(`${API_BASE_URL}/candidates/upload-pdf`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  // Create a new candidate
  createCandidate: async (candidateData) => {
    try {
      console.log("in candidateService", candidateData)
      const response = await weevaluateagent.requestsA.post(`${API_BASE_URL}/candidates/`, candidateData);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Get all candidates
  getAllCandidates: async (token) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/candidates`)
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Get a candidate by ID
  getCandidateById: async (candidateId) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/candidates/${candidateId}`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Update a candidate by ID
  updateCandidateById: async (candidateId, updatedCandidateData) => {
    try {
      const response = await weevaluateagent.requestsA.put(`${API_BASE_URL}/candidates/${candidateId}`, updatedCandidateData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  updateCandidateById1: async (candidateId, updatedCandidateData) => {
    try {
      const response = await weevaluateagent.requestsA.put(`${API_BASE_URL}/candidates/${candidateId}/status`, updatedCandidateData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Delete a candidate by ID
  deleteCandidateById: async (candidateId) => {
    try {
      const response = await weevaluateagent.requestsA.del(`${API_BASE_URL}/candidates/${candidateId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },
  signedURL: async (url) => {
    console.log(url);
    try {
      const response = await weevaluateagent.requestsA.post(
        `/resume/getSignedURL`,{url:url}
      );
      console.log("in candidate service ", response);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default candidateService;

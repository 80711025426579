import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { getAmplifyConfig } from "./Config";
import { Amplify, API } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import AWS from "aws-sdk";
import superagent from "../service/weevaluateagent";
import candidateService from "../service/candidateService";
import { useAuth0 } from "@auth0/auth0-react";
export const WeEvaluateCameraRecordings = ({ candidateMediaPipelineId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [amplifyConfig, setAmplifyConfig] = useState(null);
  const [recordings, setRecordings] = useState([]);
  const [mediaPipelineIds, setMediaPipelineIds] = useState([]);
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const token = await getAccessTokenSilently();
        superagent.setToken(token);
        const awsConfig = await candidateService.awsConfig();
        const ampConfig = await getAmplifyConfig(awsConfig);
        setAmplifyConfig(ampConfig);
        Amplify.configure(ampConfig);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    console.log(amplifyConfig);

    // Function to read text from an S3 file
    async function signedURL(key) {
      // Configure AWS SDK with your credentials and region
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await candidateService.signedURL(key);
      console.log(response);
      return response;
    }

    async function getMediaPipelineIds() {
      setMediaPipelineIds([]);
      for (let recording of recordings) {
        console.log("I am in recording", recording);
        let mediaPipelineId = {};
        mediaPipelineId.video = await signedURL(recording["composited-video"]);
        // mediaPipelineId.video=
        mediaPipelineId.timestamp = parseFloat(recording.timestamp) * 1000;
        mediaPipelineId.id = recording.mediaPipelineId;
        //   mediaPipelineId.transcript = await signedURL(recording['transcription-messages']);
        console.log(`mediaPipelineId ${JSON.stringify(mediaPipelineId)}`);
        setMediaPipelineIds((mediaPipelineIds) => [
          ...mediaPipelineIds,
          mediaPipelineId,
        ]);
      }
    }
    getMediaPipelineIds();
    console.log(mediaPipelineIds);
  }, [recordings, amplifyConfig]);

  useEffect(() => {
    const getSpecificRecordings = async () => {
      try {
        console.log("API", candidateMediaPipelineId);
        const recordingsResponse = await API.post(
          "meetingApi",
          "recordByMeetingId",
          { body: { mediaPipelineId: candidateMediaPipelineId } }
        );
        console.log(
          `Recording Response: ${JSON.stringify(recordingsResponse)}`
        );
        setRecordings(recordingsResponse);
      } catch (err) {
        console.log(err);
      }
    };
    if (amplifyConfig) {
      getSpecificRecordings();
    }
  }, [amplifyConfig]);

  return (
    <div>
      <SpaceBetween size="l">
        {mediaPipelineIds
          .slice(Math.max(mediaPipelineIds.length - 10, 0))
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((mediaPipelineId, index) => (
            <div key={index}>
              <span>{`Meeting Time: ${new Date(
                mediaPipelineId.timestamp
              ).toLocaleDateString()} ${new Date(
                mediaPipelineId.timestamp
              ).toLocaleTimeString()}`}</span>
              <ReactPlayer
                controls={true}
                url={mediaPipelineId.video}
                width="100%"
              />
            </div>
          ))}
      </SpaceBetween>
    </div>
  );
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UpdateStatusForm from './UpdateStatusForm'; // Assuming UpdateStatusForm is in the same directory
import UpdateHistory from './UpdateHistory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

const EllipsisMenu = ({ candidate,entryId, onDelete, onStatusUpdate }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showHistoryPopup, setShowHistoryPopup] = useState(false);
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setShowPopup(false); // Close the pop-up when the dropdown menu is opened
  };

  const handleViewClick = () => {
    setShowPopup(true);
    setIsOpen(false); // Close the dropdown menu when the pop-up is opened
  };
  const handleHistoryClick = () => {
    setShowHistoryPopup(true);
    setIsOpen(false); // Close the dropdown menu when the pop-up is opened
  };

  const handleOnDelete = () => {
    setIsOpen(false);
    onDelete();
  }

  const closePopup = () => {
    setShowPopup(false);
    setShowHistoryPopup(false);
    
    
  };

  return (
    <div className="relative inline-block text-left" >
      <button
        type="button"
        onClick={toggleMenu}
        className="inline-flex justify-center w-full rounded-md  border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
        aria-haspopup="true" // Indicate that the button opens a dropdown menu
        aria-expanded={isOpen} // Indicate whether the dropdown menu is open or closed
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </button>

      {/* Dropdown panel */}
      {isOpen && (
        <div className="absolute z-10 mt-2 w-40 rounded-md bg-white shadow-lg origin-top-right right-0 ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-labelledby="options-menu">
            {/* Options */}
            <button
              onClick={handleViewClick}
              className="block px-4 py-2 text-sm text-green-500 hover:bg-gray-100 w-full text-left"
              role="menuitem"
            >
              Update Status
            </button>
            <Link to={ candidate?.email? `/ats/candidate/${entryId}`:`/ats/job/${entryId}`} className="text-blue-500 hover:underline block px-4 py-2 text-sm w-full text-left">
              View
            </Link>
             {/* Options */}
             <button
              onClick={handleHistoryClick}
              className="block px-4 py-2 text-sm text-green-500 hover:bg-gray-100 w-full text-left"
              role="menuitem"
            >
              Add History
            </button>
            <button onClick={handleOnDelete} className="text-red-500 hover:underline block px-4 py-2 text-left text-sm w-full" role="menuitem">
              Delete
            </button>
          </div>
        </div>
      )}

      {/* Pop-up */}
      {showPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center" aria-modal="true">
          <div className="bg-white p-6 rounded-lg" role="dialog" aria-labelledby="update-status-dialog-title">
            <h1 id="update-status-dialog-title" className="mb-4 text-black">Update Candidate Status</h1>
            <UpdateStatusForm candidate={candidate} entryId={entryId} onStatusUpdate={onStatusUpdate} onClose={closePopup} />
          </div>
        </div>
      )}
      {showHistoryPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center" aria-modal="true">
          <div className="bg-white p-6 rounded-lg" role="dialog" aria-labelledby="update-status-dialog-title">
            <h1 id="update-status-dialog-title" className="mb-4 text-black">Update Candidate History</h1>
            <UpdateHistory candidate={candidate} entryId={entryId}  onClose={closePopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EllipsisMenu;

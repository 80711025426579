import React from 'react';
import { Pie } from 'react-chartjs-2';

const PercentageOfFitChart = ({percentage}) => {
    const data = {
        labels: percentage.labels,
        datasets: [
          {
            data: percentage.data, // Example values (replace with actual data)
            backgroundColor: ['#36A2EB', '#FFCE56'], // Colors for each segment
          },
        ],
      };
      
      const options = {
        maintainAspectRatio: true, // This allows you to set custom dimensions
        responsive: false, // Set to false to prevent auto-resizing
    
        // Set your desired width and height
      
          };
      
    return (
      <div>
        <h4>Percentage of Fit Chart</h4>
        <center><Pie data={data} options={options} width={250} height={250}/></center>
      </div>
    );
  };
  
  export default PercentageOfFitChart;
  
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import candidateService from "../../service/candidateService";

function InterviewTracker() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [interviewData, setInterviewData] = useState();
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    fetchInterviewData();
  }, [params,getAccessTokenSilently]);
  const fetchInterviewData = async () => {
    const token = await getAccessTokenSilently();
    superagent.setToken(token);

    candidateService
      .generateInterviewQuestions(params.jobId, params.candidateId) // Replace with the actual job ID and candidate ID
      .then((response) => {
        setInterviewData(response);
        const hasInterviewCompleted = response?.interview?.status?.some(item => item.status === 'Interview Completed');
       if(hasInterviewCompleted){
        setShowGenerateButton(hasInterviewCompleted)
       }
      })
      .catch((error) => {
        console.error("Error in Fetching InterviewData:", error);
      });
  };

  const getStatusColor = (status) => {
    switch (status) {
        case 'Invitation Sent':
            return 'text-blue-500';
        case 'Code Verified':
            return 'text-yellow-500';
        case 'Interview Completed':
            return 'text-green-500';
        default:
            return 'text-gray-500';
    }
};
const handleGetReport = async () => {
    navigate(`/interviewgenius/report/${params.jobId}/${params.candidateId}`);
}
  return (
    <div className="container mx-auto px-4  h-80">
      {/* Header with button floated right */}
      <div className="mb-4 flex justify-end">
      <button  className={`py-2 px-4 mr-4 rounded font-bold ${showGenerateButton ? 'bg-gray-400 text-gray-600 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600 text-white hover:text-white'}`}
          disabled={!showGenerateButton}
        >
         Cancel Interview
        </button>
        <button  className={`py-2 px-4 rounded font-bold ${!showGenerateButton ? 'bg-gray-400 text-gray-600 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 text-white hover:text-white'}`}
          onClick={()=> handleGetReport()}
        >
          Generate Report
        </button>
      </div>

      {/* Main Content Grid */}
      <div className="flex flex-wrap -mx-2">
        {/* Candidate Details Section spanning two columns */}
        <div className="w-full px-2 mb-6">
          <section className="bg-white rounded-lg shadow p-6">
            <div className="flex mt-4">
              <div className="w-1/2 pr-2">
                <p>
                  <strong>Name : </strong>
                  {interviewData?.fullName}
                </p>
                <p>
                  <strong>Email:</strong> {interviewData?.email}
                </p>
              </div>
              <div className="w-1/2 pl-2">
                <p>
                  <strong>Phone:</strong> (123) 456-7890
                </p>
                <p>
                  <strong>Position:</strong> {interviewData?.title}
                </p>
              </div>
            </div>
          </section>
        </div>

        {/* Left Column */}
        <div className="w-full md:w-1/2 px-2 ">
          <section className="bg-white rounded-lg shadow p-6 mb-6 h-100">
            <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
              Interview Questions
            </h2>
            <div className="overflow-auto h-64">
    <ul className="list-no pl-5 mt-4 text-gray-700 overflow-hidden">
       
        {interviewData?.interview?.finalQuestionList?.map((question, index) => (
            <li key={index} className={`p-4 py-2 ${index % 2 === 0 ? 'bg-gray-200' : 'bg-white-200'}`}>{question?.question}</li>
        ))}
    </ul>
</div>

          </section>
        </div>

        {/* Right Column */}
        <div className="w-full md:w-1/2 px-2 overflow-auto h-80">
          <section className="bg-white rounded-lg shadow p-6 mb-6">
            <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
              Interview Tracker
            </h2>
             <table  style={{height:'300px'}} className="table-auto w-full mt-4 overflow-auto h-80">
              <thead className="bg-blue-100">
                <tr>
                  <th className="px-4 py-2 text-gray-600">Date</th>
                  <th className="px-4 py-2 text-gray-600">Time</th>
                  <th className="px-4 py-2 text-gray-600">Status</th>
                </tr>
              </thead>
              <tbody className=" ">
              {interviewData?.interview.status?.map((item, index) => (
    <tr key={index}>
        <td className="border px-4 py-2">{new Date(item.updatedDate).toLocaleDateString()}</td>
        <td className="border px-4 py-2">{new Date(item.updatedDate).toLocaleTimeString()}</td>
        <td className={`border px-4 py-2 ${getStatusColor(item.status)}`}>{item.status}</td>
    </tr>
))}

                {/* More rows can be added here */}
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </div>
  );
}

export default InterviewTracker;

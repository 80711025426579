import React from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table';
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';
import { Button, PageButton } from './shared/Button';
import { classNames } from './shared/Utils';
import { SortIcon, SortUpIcon, SortDownIcon } from './shared/Icons';

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700 mb-4">Search: </span>
      <input
        type="text"
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  
  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function StatusPill({ value }) {
  const rating = parseInt(value);

  let backgroundColor;
  let textColor;
  
  if (rating >= 4) {
    backgroundColor = "bg-green-100";
    textColor = "text-green-800";
  } else if (rating === 3) {
    backgroundColor = "bg-yellow-100";
    textColor = "text-yellow-800";
  } else {
    backgroundColor = "bg-red-100";
    textColor = "text-red-800";
  }

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        backgroundColor,
        textColor
      )}
    >
      {value}
    </span>
  );
};
export function AvatarCell({ value, column, row }) {
  console.log("AvatarCell is");
  
  const randomBackgroundColor = getRandomColor(value);
  const fontColor ='white';

  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        {row.original[column.imgAccessor] ? (
          <img className="h-10 w-10 rounded-full" src={row.original[column.imgAccessor]} alt="" />
        ) : (
          <div className="h-10 w-10 rounded-full flex items-center justify-center" style={{ backgroundColor: randomBackgroundColor, color: fontColor }}>
            {value?.charAt(0).toUpperCase()}
          </div>
        )}
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">{value}</div>
        <div className="text-sm text-gray-500">{row.original[column.emailAccessor]}</div>
      </div>
    </div>
  );
}

const getRandomColor = (value) => {
  const letterColors = {
    a: '#5E2B0B', b: '#4D4D00', c: '#4D4D00', d: '#3F3F0B', e: '#0B4D40', f: '#0B4D3F', g: '#0B3F4D',
    h: '#00334D', i: '#1F003F', j: '#40003F', k: '#3F004D', l: '#4D003F', m: '#4D003F', n: '#4D000B',
    o: '#5E260B', p: '#5E4D0B', q: '#4D5E0B', r: '#265E0B', s: '#0B5E2A', t: '#0B4D5E', u: '#0B265E',
    v: '#0B0B4D', w: '#1F0B3F', x: '#400B3F', y: '#3F0B4D', z: '#4D0B1F'
  };
  

  const firstChar = value?.charAt(0).toLowerCase();
  return letterColors[firstChar];
};

function Table({ columns, data,html,style }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
  },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination,  // new
  );

  // Render the UI for your table
  return (
    <div className="flex flex-col h-full shadow-lg p-2 bg-white rounded-lg" >
     <div className="sm:flex sm:gap-x-2 justify-between items-center">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      <div className="flex justify-end items-center m-2">{html}</div>
      </div>
      {/* table */}
      <div className="flex-1 overflow-auto" >
        <table className="min-w-full divide-y divide-gray-200 overflow-auto" >
          <thead className="bg-gray-50" style={{paddingRight:'20px',  position: "sticky",top: "-1x",zIndex: 1,display:'table', width:'99%'}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" 
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{width:column.width?column.width:((100/headerGroup.headers.length))+'%'}}
                  >
                    <div className="flex items-center justify-between">
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <SortDownIcon className="w-4 h-4 text-gray-400" />
                            : <SortUpIcon className="w-4 h-4 text-gray-400" />
                          : (
                            <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                          )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody style={{height:style.height?style.height:'400px',overflow:'auto',display:'block'}}
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200 overflow-auto"
          >
            {page.map((row, i) => {  // new
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} style={{display:'table', width:'100%'}}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="px-6 py-4 whitespace-nowrap"
                        role="cell"
                        style={{width:cell.column.width?cell.column.width:100/row.cells.length+'%'}}
                      >
                        {cell.column.Cell.name === "defaultRenderer"
                          ? <div className="text-sm text-gray-500">{cell.render('Cell')}</div>
                          : cell.render('Cell')
                        }
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="py-3 flex items-center justify-between mt-auto">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex gap-x-2 items-baseline">
            <span className="text-sm text-gray-700">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
            </span>
            <label>
              <span className="sr-only">Items Per Page</span>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[5, 10, 20].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => nextPage()}
                disabled={!canNextPage
                }>
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default  React.memo(Table);

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UserPlanProvider } from './context/UserPlanContext';
import Auth0ProviderWithHistory from './Auth0Provider';
import { ModalProvider } from 'react-modal-hook';

import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from './config';
import history from './utils/history';
import { store } from './modules/ChatBot/store/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
const config = getConfig();
const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
  <Auth0Provider {...providerConfig}>
      <UserPlanProvider>
      <ModalProvider>
      <Provider store={store}>  
        <App />
        <ToastContainer />
        </Provider>
        </ModalProvider>
      </UserPlanProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

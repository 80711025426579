import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Accordion from '../../components/Accordion';
import WorkExperience from '../../components/WorkExperience';
import Education from '../../components/Education';
import Skills from '../../components/Skills';
import MandatoryData from '../../components/MandatoryFields';
import candidateService from '../../services/candidateService';
import superagent from "../../services/weevaluateagent";
function CandidateForm() {
  const {isLoading, isAuthenticated, user,getAccessTokenSilently} = useAuth0();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isExisted, setIsExisted] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isMainDetailsEdit, setIsMainDetailsEdit] = useState(false);
  const [isWorkExpDetailsEdit, setIsWorkExpDetailsEdit] = useState(false);
  const [isEduDetailsEdit, setIsEduDetailsEdit] = useState(false);
  const [isSkillDetailsEdit, setIsSkillDetailsEdit] = useState(false);
  const [isWorkExpAccordionOpen, setIsWorkExpAccordionOpen] = useState(false);
  const [isEduAccordionOpen, setIsEduAccordionOpen] = useState(false);
  const [isSkillAccordionOpen, setIsSkillAccordionOpen] = useState(false);



  const toggleWorkExpAccordion = () => {
    setIsWorkExpAccordionOpen(!isWorkExpAccordionOpen);
  };
  const toggleEduAccordion = () => {
    setIsEduAccordionOpen(!isEduAccordionOpen);
  };
  const toggleSkillAccordion = () => {
    setIsSkillAccordionOpen(!isSkillAccordionOpen);
  };
  // Default form field values
  const candidate_Data = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    resume: '',
    overAllExp:'',
    linkedin: '',
    github: '',
    workExperience: [{ jobTitle: '', company: '', location: '', startDate: '', endDate: '' }],
    education: [{ degree: '', institution: '', graduationDate: '' }],
    skills: [{skill:'',availability:''}],
    status: [{Status:'',Date:''}],
  };

  const [formData, setFormData] = useState(candidate_Data);

  useEffect(() => {
    if (id) {
      console.log('candidate Id', id)
      setIsExisted(true)
      fetchCandidateData(id)
    }
  }, [id]);

  const addWorkExperience = () => {
    setFormData(prevState => ({
      ...prevState,
      workExperience: [...prevState.workExperience, { jobTitle: '', company: '', location: '', startDate: '', endDate: '' }]
    }));
  };

  const addEducation = () => {
    setFormData(prevState => ({
      ...prevState,
      education: [...prevState.education, { degree: '', institution: '', graduationDate: '' }]
    }));
  };

  const addSkills = () => {
    setFormData(prevState => ({
      ...prevState,
      skills: [...prevState.skills, { skill: '',availability:'' }],
      
    }));
  };
  
  const handleChange = (e, index) => {
    const { name, value } = e.target;
  
    // Log the event details
    console.log("Event details:", { name, value });
  
    if (name.includes('_')) {
      const [fieldName, experienceIndex] = name.split('_');
  
      // Log the split values
      console.log("Split field name:", fieldName);
      console.log("Experience index:", experienceIndex);
  
      const updatedFormData = { ...formData };
  
      // Log the updated form data before modification
      console.log("Before update:", updatedFormData);
  
      const isFieldInWorkExperience = candidate_Data.workExperience.some(workObj => fieldName in workObj);
  
      // Log if the field is in work experience
      console.log("Field is in work experience:", isFieldInWorkExperience);
  
      if (isFieldInWorkExperience) {
        updatedFormData['workExperience'][index][fieldName] = value;
      }
  
      const isFieldInEducation = candidate_Data.education.some(eduObj => fieldName in eduObj);
  
      // Log if the field is in education
      console.log("Field is in education:", isFieldInEducation);
  
      if (isFieldInEducation) {
        updatedFormData['education'][index][fieldName] = value;
      }
  
      const isFieldInSkills = candidate_Data.skills.some(sklObj => fieldName in sklObj);
  
      // Log if the field is in skills
      console.log("Field is in skills:", isFieldInSkills);
  
      if (isFieldInSkills) {
        updatedFormData['skills'][index][fieldName] = value; // This is where the error occurs
      }
      console.log("After update:", updatedFormData);
  
      setFormData(updatedFormData);
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response=await candidateService.createCandidate({formData});
      setFormData(response.formData);
      console.log('Candidate form data submitted successfully');
    } catch (error) {
      console.error('Error submitting candidate form data:', error);
    }
    
  };

  const handleUpdate = async () => {
   
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response=await candidateService.updateCandidateById(id,formData);
      console.log('Candidate updated:', response.data);
    } catch (error) {
      console.error('Error updating candidate:', error);
    }
  };

  const fetchCandidateData = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response=await candidateService.getCandidateById(id);
    console.log("fetchCandidateData",response)
    setIsEditing(false)
      setFormData(response);
      console.log('Candidate form data updated successfully');
    } catch (error) {
      console.error('Error updating candidate form data:', error);
    }
  };


  const deleteEducation = async (index) => {
    try {

      setFormData(prevState => {
        const updatedEducation = [...prevState.education];
        updatedEducation.splice(index, 1);
        return {
          ...prevState,
          education: updatedEducation
        };
      });
      
    } catch (error) {
      console.error('Error deleting education entry:', error);
    }
  };

  const deleteWorkExperience = async (index) => {
    try {

      setFormData(prevState => {
        const updatedWorkExperience = [...prevState.workExperience];
        updatedWorkExperience.splice(index, 1);
        return {
          ...prevState,
          workExperience: updatedWorkExperience
        };
      });
      
    } catch (error) {
      console.error('Error deleting workExperience entry:', error);
    }
  };

  const deleteSkills = async (index) => {
    try {

      setFormData(prevState => {
        const updatedSkills = [...prevState.skills];
        updatedSkills.splice(index, 1);
        return {
          ...prevState,
          skills: updatedSkills
        };
      });
      
    } catch (error) {
      console.error('Error deleting skills entry:', error);
    }
  };
  const mainDetailsEdit = () => {
    setIsMainDetailsEdit(true);
  };

  const mainDetailsSave = () => {
    setIsMainDetailsEdit(false);
    handleUpdate();
  };

  const workExpDetailsEdit = () => {
    setIsWorkExpDetailsEdit(true);
    setIsEditing(true);
  };

  const workExpDetailsSave = () => {
    setIsWorkExpDetailsEdit(false);
    setIsEditing(false);
    handleUpdate();
  };

  const eduDetailsEdit = () => { // Corrected function name to match save function
    setIsEduDetailsEdit(true);
    setIsEditing(true);
  };

  const eduDetailsSave = () => {
    setIsEduDetailsEdit(false);
    setIsEditing(false);
    handleUpdate();
  };
  const skillDetailsEdit = () => { // Corrected function name to match save function
    setIsSkillDetailsEdit(true);
    setIsEditing(true);
    
  };

  const skillDetailsSave = () => {
    setIsSkillDetailsEdit(false);
    setIsEditing(false);
    handleUpdate();
  };

  const gotoCandidateInfo = () => {
    navigate("/ats/candidateInfo");
  };

  return (

    <div className="max-w-4xl mx-auto bg-white rounded p-8 shadow-md w-full">
      <div className="flex justify-end">
  <button
    type="button"
    onClick={gotoCandidateInfo}
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
  >
    Show All Candidates
  </button>
</div>

      
      <div >
      {/* Accordion for Mandatory Fields */}
         <MandatoryData
            formData={formData}
            isExisted={isExisted}
            isMainDetailsEdit={isMainDetailsEdit}
            handleChange={handleChange}
            mainDetailsSave={mainDetailsSave}
            mainDetailsEdit={mainDetailsEdit}
          />
        
        {/* Accordion for Work Experience */}
        <Accordion title="Work Experience" isAccordionOpen={isWorkExpAccordionOpen} toggleAccordion={toggleWorkExpAccordion}>
          <WorkExperience
            workExperience={formData.workExperience}
            isExisted={isExisted}
            isEditing={isEditing}
            isWorkExpDetailsEdit={isWorkExpDetailsEdit}
            handleChange={handleChange}
            deleteWorkExperience={deleteWorkExperience}
            addWorkExperience={addWorkExperience}
            workExpDetailsSave={workExpDetailsSave}
            workExpDetailsEdit={workExpDetailsEdit}
          />
        </Accordion>
        {/* Accordion for Education */}
          <Accordion title="Education" isAccordionOpen={isEduAccordionOpen} toggleAccordion={toggleEduAccordion}>
              <Education
            
            education={formData.education}
            isExisted={isExisted}
            isEditing={isEditing}
            isEduDetailsEdit={isEduDetailsEdit}
            handleChange={handleChange}
            deleteEducation={deleteEducation}
            addEducation={addEducation}
          eduDetailsSave={eduDetailsSave}
            eduDetailsEdit={eduDetailsEdit}

                />
          </Accordion>
        {/* Accordion for Skills */}
          <Accordion title="Skills" isAccordionOpen={isSkillAccordionOpen} toggleAccordion={toggleSkillAccordion}> 
              <Skills
            
                skills={formData.skills}
                  isExisted={isExisted}
                  isEditing={isEditing}
                  isSkillDetailsEdit={isSkillDetailsEdit}
                  handleChange={handleChange}
                  deleteSkills={deleteSkills}
                  addSkills={addSkills}
                  skillDetailsSave={skillDetailsSave}
                  skillDetailsEdit={skillDetailsEdit}

              />
          </Accordion>

          <div className="flex justify-end">
          <button
    onClick={gotoCandidateInfo}
    type="button"
    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4  rounded focus:outline-none focus:shadow-outline"
  >
    Cancel
  </button>
  <button
    onClick={handleSubmit}
    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-2 rounded focus:outline-none focus:shadow-outline ${isExisted ? 'hidden' : ''}`}
  >
    Save Candidate
  </button>
 
</div>
      </div>
    </div>
  );
}

export default CandidateForm;

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import './AdminPanel.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faBriefcase, faChalkboardUser, faCircleQuestion, faGear, faHouse, faPaperPlane, faPersonThroughWindow, faUser, faUserGroup, faUsers, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import Navigation from "./Navigation";
import { useAuth0 } from "@auth0/auth0-react";
import Breadcrumb from "./Breadcrumb";
import logo from '../assets/logo.png'
import ChatBot from '../modules/ChatBot';

const AdminPanel = (sidebar) => {
  const {logout } = useAuth0();
  const [showSidebar, setShowSidebar] = useState(true);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const menuItems = [
    { label: 'Dashboard', path: '/dashboard', icon: faHouse },
    { label: 'Invitations', path: '/interviewgenius/scheduleinterviews', icon: faPaperPlane },
    { label: 'Interview Genius', path: '/interviewgenius/analysisList', icon: faUserSecret },
    { label: 'Crew Evaluator', path: '/crewevaluator/assessmentList', icon: faChalkboardUser },
    { label: 'Jobs', path: '/ats/jobInfo', icon: faBriefcase },
    { label: 'Candidates', path: '/ats/candidateInfo', icon: faUserGroup },
  //  { label: 'Questions', path: '/questions', icon: faCircleQuestion },
   // { label: 'Team', path: '/ats/team', icon: faUsers },
    { label: 'Profile', path: '/ats/profile', icon: faUser },
   // { label: 'Settings', path: '/settings', icon: faGear },
    { label: 'Logout', path: '/logout', icon: faPersonThroughWindow },
  ];
  return (
    <div className="bg-gray-100 h-screen" >
      <header className="bg-white shadow-lg h-20 flex items-center justify-between px-6">
        <div className="flex items-center">
        {sidebar.sidebar &&  <button onClick={toggleSidebar} className="mr-4 focus:outline-none">
            <svg
              className="h-6 w-6 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>}
          <img
           src={logo}
            alt="Logo"
            className="mr-4"
          />
          <h1 className="text-2xl font-bold">WeEvaluate</h1>
        </div>
        <div className="flex items-center">
    
          <div className="relative inline-block text-left">
            <button className="bg-white rounded-full p-2 focus:outline-none focus:shadow-outline" onClick={()=>{logout()}}>
            <FontAwesomeIcon icon={faPersonThroughWindow} /> Logout
            </button>
          </div>
        </div>
      </header>

  <div className="flex h-full">
        {/* Sidebar */}
        {sidebar.sidebar && <div
          className={`navbar bg-white shadow-lg  p-6 transition-all duration-300 ${
            showSidebar ? "translate-x-0" : "-translate-x-full" 
          }  ${showSidebar ? "w-45" : "w-14"}`}
          
        >
         <Navigation menuItems={menuItems} showSidebar={!showSidebar} />
        </div>}
       
        {/* Main Content */}
        <div
          className="flex-1 p-4 h-screen bg-gray-200"
          style={{ overflow: "auto" }}
        >
          <Breadcrumb/>
          <Outlet />
          <ChatBot/>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;

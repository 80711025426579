// SelectCandidate.js
import React, { useState } from "react";
import "./SelectCandidate.css"; // Make sure to create a corresponding CSS file
import { useNavigate } from "react-router-dom";
import JobsList from "../Job/JobsList";

import CandidatesList from "../Candidate/CandidatesList";

import HelpSection from "../../../../components/HelpSection";

function SelectCandidate() {
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const navigate = useNavigate();
  const handleJobSelection = (job) => {
    console.log(job);
    setSelectedJob(job);
  };
  const handleCandidateSelection = (job) => {
    setSelectedCandidate(job);
  };
  const handleAnalysisClick = (jobId, candidateId) => {
    // Navigate to the CandidateAnalysis component with jobId and candidateId
    navigate(`/interviewgenius/analysis/${jobId}/${candidateId}`);
  };
  const limitStringByCommas = (str, limit) => {
    const parts = str.split(",");
    return parts.slice(0, limit).join(",") + (parts.length > limit ? "," : "");
  };

  const displaySkills = (skills) => {
    let displayedSkills = "";
    for (let i = 0; i < Math.min(5, skills.length); i++) {
      displayedSkills += skills[i].skill;
      if (i < Math.min(4, skills.length - 1)) {
        displayedSkills += ", ";
      }
    }
    return displayedSkills;
  };
  // Modal content for creating a candidate
  const steps = [
    {
      title: "Step 1: Select Job or Add Job",
      details:
        "Select Job or If no list of jobs is available, click on 'Add Job'. You will see two tabs: 'Generate Job Description' and 'Upload Job'.",
    },
    {
      title: "Step 2: Generate Job Description",
      details:
        "Provide job title, experience (years), and required skills. Click 'AI Generate Job Description' to generate.",
    },
    {
      title: "Step 3: Review and Save Job Description",
      details:
        "After the job description is generated, click 'Save' or 'Edit' to modify details, then save again.",
    },
    {
      title: "Step 4: Candidate Selection and Resume Upload",
      details:
        "Select a candidate or click 'Add Candidate' to upload a resume and add them to your list.",
    },
    {
      title: "Step 5: Start Final Analysis",
      details: "Click 'Start Analysis' to evaluate candidate fit for the job.",
    },
    {
      title: "Step 6: Generate Interview Questions",
      details: "Click 'Generate Question' to create interview questions.",
    },
    {
      title: "Step 7: Send Invite to Candidate",
      details: "Finally, send an invite to the candidate.",
    },
  ];
  return (
    <div className="select-candidate">
      <div className={!selectedJob ? "grid-container-2" : "grid-container-3"}>
        {/* Job Section */}
        <div className=" card">
          {/* Button to open modal */}

          <JobsList onSelectJob={handleJobSelection} />
        </div>

        {/* Candidate Section */}
        {selectedJob ? (
          <div
            className=" card" 
            style={{ display: !selectedJob ? "none" : "block" }}

          >
            <CandidatesList onSelectCandidate={handleCandidateSelection} jobId={selectedJob} />
          </div>
        ) : (
          <HelpSection steps={steps} />
        )}

        {/* Info Section */}
        {selectedJob && (
          <div className="card" style={{minWidth:'350px'}}>
            {/* Start Analysis Button */}
            {selectedCandidate && selectedJob && (
              <div className="card h-16 bg-white border border-gray-300 rounded-lg shadow-md p-6  px-4 flex items-center justify-between mb-4">
                <button
                  className="bg-blue-500 hover:bg-blue-700  text-white font-bold py-2 px-4 rounded"
                  onClick={() =>
                    handleAnalysisClick(selectedJob._id, selectedCandidate._id)
                  }
                >
                  Start Analysis
                </button>
                <button style={{display:'none'}} className="bg-green-300 hover:bg-green-400 text-gray-700 font-bold py-2 px-4 rounded">
                  Send To Team
                </button>
              </div>
            )}

            <div className={`grid grid-cols-1 md:grid-cols-1 gap-4 `}>
              {/* Job Info */}
              {selectedJob && (
                <div className="card bg-white border border-gray-300 rounded-lg shadow-md p-6">
                  <div>
                    <b>{selectedJob.title}</b>

                    <p>Experience: {selectedJob.experience}</p>
                    <p>
                      Open Positions:{" "}
                      {selectedJob.noOfPositions
                        ? selectedJob.noOfPositions
                        : 0}
                    </p>
                  </div>
                </div>
              )}
              {/* Candidate Info */}
              {selectedCandidate && (
                <div className="card bg-white border border-gray-300 rounded-lg shadow-md p-6">
                  <div>
                    <b>{selectedCandidate.fullName}</b>
                    <p className="wrapped-text">
                      Skills: {displaySkills(selectedCandidate.skills)}
                    </p>
                    <p>Experience: {selectedCandidate.experience}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SelectCandidate;

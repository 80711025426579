import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateConcept = () => {
    const navigate = useNavigate();
    const handleSaveConcept =() =>{
        navigate("/crewevaluator/listOfConcepts");
     }
    return (
        <div className="container mx-auto py-8">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                {/* Concept name input */}
                <div className="mb-4">
                    <label htmlFor="conceptName" className="block text-gray-700 text-sm font-bold mb-2">
                        Concept Name
                    </label>
                    <input id="conceptName" type="text" className="form-input mt-1 block w-full" placeholder="Enter Concept Name" />
                </div>
                {/* Subject name selection */}
                <div className="mb-4">
                    <label htmlFor="subjectName" className="block text-gray-700 text-sm font-bold mb-2">
                        Select Subject Name
                    </label>
                    <select id="subjectName" className="form-select mt-1 block w-full">
                        <option value="" disabled selected>Select Subject</option>
                        {/* Populate options dynamically */}
                        <option value="math">Mathematics</option>
                        <option value="science">Science</option>
                        <option value="english">English</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                {/* Grade selection */}
                <div className="mb-4">
                    <label htmlFor="grade" className="block text-gray-700 text-sm font-bold mb-2">
                        Select Grade
                    </label>
                    <select id="grade" className="form-select mt-1 block w-full">
                        <option value="" disabled selected>Select Grade</option>
                        {/* Populate options dynamically */}
                        <option value="1">Grade 1</option>
                        <option value="2">Grade 2</option>
                        <option value="3">Grade 3</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                {/* Concept file input */}
                <div className="mb-4">
                    <label htmlFor="conceptFile" className="block text-gray-700 text-sm font-bold mb-2">
                        Concept File
                    </label>
                    <input id="conceptFile" type="file" className="form-input mt-1 block w-full" placeholder="Upload Concept File" />
                </div>
                {/* Submit button */}
                <div className="flex items-center justify-end">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={()=> handleSaveConcept()}>
                        Save Concept
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateConcept;

import React, { useState } from 'react';
import jobService from '../../service/jobService';
import superagent from "../../service/weevaluateagent";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import JobForm from '../../../ApplicationTrackingSystem/pages/Job/JobForm';
import Loading from '../../../../components/Loading';
import AILoading from '../../../../components/AILoading';

function JobDescriptionForm({setSelectedJob, setIsExisted = () => {} }) {
  const [jobTitle, setJobTitle] = useState('');
  const [requiredSkills, setRequiredSkills] = useState('');
  const [experience, setExperience] = useState('');
  const [generatedDescription, setGeneratedDescription] = useState(false);
  const [jobId, setJobId]= useState(null);
  const [isRequested,setIsRequested] =useState(false);
  
  const navigate = useNavigate();
  
  const { getAccessTokenSilently,user } = useAuth0();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call the function to generate the job description
    setIsRequested(true)
    const newJobId = generateJobDescription(jobTitle, requiredSkills, experience);
   
    
    //setGeneratedDescription(description);
  };

  // Function to generate a job description using AI
  const generateJobDescription = async (jobTitle, requiredSkills, experience) => {
   
try {
  const token = await getAccessTokenSilently();
  superagent.setToken(token);
  const response = await jobService.JobDescriptionByAI(jobTitle, requiredSkills, experience,user.email);
  console.log(response)
  
  if(response){
    setIsRequested(false)
    setSelectedJob(response)
    setGeneratedDescription(true);
    console.log(response._id);
    setJobId(response._id);
    if (typeof setIsExisted === 'function') {
      setIsExisted(true);
  }
  }
} catch (error) {
  console.error('Error uploading resume:', error);
  // Handle the error as needed
}

  };

  return (
<div className="m-4">
     {!generatedDescription && (!isRequested ? <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4 p-6 h-auto bg-white rounded-lg shadow-md">
        <div>
          <label htmlFor="jobTitle" className=" text-gray-700 font-medium">Job Title:</label>
          <input type="text" id="jobTitle" className="form-input  w-full  h-8 border" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
        </div>
        <div>
          <label htmlFor="experience" className=" text-gray-700 font-medium">Experience (years):</label>
          <input type="number" id="experience" className="form-input  w-full h-8  border" value={experience} onChange={(e) => setExperience(e.target.value)} />
        </div>
        <div>
          <label htmlFor="requiredSkills" className=" text-gray-700 font-medium">Required Skills (comma-separated):</label>
          <input type="text" id="requiredSkills" className="form-input  w-full h-8  border" value={requiredSkills} onChange={(e) => setRequiredSkills(e.target.value)} />
        </div>
        
        <div className="col-span-2"  style={{float:'right'}}>
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-80">Generate Job Description</button>
        </div>
      </form>:<AILoading text="Generating Job" type="model"/>)}

      {generatedDescription && (
        <div style={{height:'400px', overflow:'auto'}}>
        <JobForm jobId={jobId} />
        </div>
      )}
    </div>
  );
}

export default JobDescriptionForm;

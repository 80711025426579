import React, { useState } from 'react';
import './OTabs.css'; // Import the CSS file containing the styles

const OTabs  = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].name); // Initialize active tab to the first tab
  
    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };
  
    return (
      <div>
        <div className="tabs-container">
          {tabs.map(tab => (
            <button
              key={tab.name}
              className={`tab-button ${activeTab === tab.name ? 'active' : ''}`}
              onClick={() => handleTabClick(tab.name)}
            >
              {tab.label}
            </button>
          ))}
        </div>
  
        {tabs.map(tab => (
          <div
            key={tab.name}
            className={`w-full p-4 tab-content ${activeTab === tab.name ? 'active' : ''}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    );
  };
  

export default OTabs;

import React from 'react';
import { PlusSmIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import InlineButtonControl from './InlineButtonControl';
const WorkExperience = ({ workExperience, isExisted, isEditing, isWorkExpDetailsEdit, handleChange, deleteWorkExperience, addWorkExperience, workExpDetailsSave, workExpDetailsEdit }) => {
  
  return (
    <div>
      <div className="flex justify-end items-center mt-2">
        {isEditing && (
          <div className=" cursor-pointer" onClick={addWorkExperience}>
            <PlusSmIcon className="w-8 h-8 text-blue-500 mr-2" />
          </div>
        )}
        <div>
          {isExisted && <InlineButtonControl saveClick={workExpDetailsSave} editClick={workExpDetailsEdit} />}
        </div>
      </div>
      {workExperience.map((experience, index) => (
        <div key={index} className="mb-4 flex flex-row border rounded py-2 px-4">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label htmlFor={`jobTitle_${index}`} className="block text-gray-700 font-bold mb-2 leftside_text">Job Title</label>
              {!isExisted || isWorkExpDetailsEdit ? (
                <input 
                  type="text" 
                  id={`jobTitle_${index}`} 
                  name={`jobTitle_${index}`} 
                  value={experience['jobTitle']} 
                  onChange={(e) => handleChange(e, index)} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  placeholder="Job Title" 
                />
              ) : (
                <div className='data-value'>{experience.jobTitle}</div>
              )}
            </div>
            <div>
              <label htmlFor={`company_${index}`} className="block text-gray-700 font-bold mb-2 leftside_text">Company</label>
              {!isExisted || isWorkExpDetailsEdit ? (
                <input 
                  type="text" 
                  id={`company_${index}`} 
                  name={`company_${index}`} 
                  value={experience.company} 
                  onChange={(e) => handleChange(e, index)} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  placeholder="Company" 
                />
              ) : (
                <div className='data-value'>{experience.company}</div>
              )}
            </div>
            <div>
              <label htmlFor={`location_${index}`} className="block text-gray-700 font-bold mb-2 leftside_text">Location</label>
              {!isExisted || isWorkExpDetailsEdit ? (
                <input 
                  type="text" 
                  id={`location_${index}`}
                  name={`location_${index}`}
                  value={experience.location}
                  onChange={(e) => handleChange(e, index)} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  placeholder="location" 
                />
              ) : (
                <div className='data-value'>{experience.location}</div>
              )}
            </div>
            <div>
              <label htmlFor={`startDate_${index}`} className="block text-gray-700 font-bold mb-2 leftside_text">Start Date</label>
              {!isExisted || isWorkExpDetailsEdit ? (
                <input 
                  type="date" 
                  id={`startDate_${index}`}
                  name={`startDate_${index}`}
                  value={experience.startDate}
                  onChange={(e) => handleChange(e, index)} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  placeholder="startDate" 
                />
              ) : (
                <div className='data-value'>{experience.startDate}</div>
              )}
            </div>
            <div>
              <label htmlFor={`endDate_${index}`} className="block text-gray-700 font-bold mb-2 leftside_text">End Date</label>
              {!isExisted || isWorkExpDetailsEdit ? (
                <input 
                  type="date" 
                  id={`endDate_${index}`}
                  name={`endDate_${index}`}
                  value={experience.endDate}
                  onChange={(e) => handleChange(e, index)} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  placeholder="endDate" 
                />
              ) : (
                <div className='data-value'>{experience.endDate}</div>
              )}
            </div>
          </div>
        <div className='ml-3'>
          {isExisted && isWorkExpDetailsEdit && (
            <FontAwesomeIcon icon={faTrash}  onClick={() => deleteWorkExperience(index)} className="text-red-500" />
          )}
        </div>
      </div>
    ))}
    </div>
  );
};

export default WorkExperience;

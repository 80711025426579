import React, { useState } from 'react';
import candidateService from '../services/candidateService';

const UpdateHistory = ({ candidate, entryId,  onClose }) => {
  const [addHistory, setaAddHistory] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleHistoryChange = (event) => {
    setaAddHistory(event.target.value);
  };

  const handleHistoryClick = async () => {
   

    
    try {
      const currentDate = new Date();
      const dateString = currentDate.toString();

      candidate.historyUpdates.push({
        history: addHistory,
        updatedDate: dateString,
      });

      // Update candidate history
      const response = await candidateService.updateCandidateById(entryId, candidate);
     // onHistoryUpdate(entryId, addHistory);
      console.log('Candidate History updated:', response);
      onClose();
    } catch (error) {
      console.error('Error updating history:', error);
      setError('Failed to update history. Please try again.');
    }
  };

  return (
    <div>
      <textarea value={addHistory} onChange={handleHistoryChange} className='text-black'></textarea>
      
      <div className="mt-4">
        <button onClick={handleHistoryClick} className={`px-4 py-2 bg-gray-800 text-white rounded hover:bg-blue-700 ${isLoading && 'opacity-50 cursor-not-allowed'}`} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save'}
        </button>
        <button onClick={onClose} className="ml-2 px-4 py-2 bg-gray-800 text-white rounded hover:bg-green-700">Close</button>

      </div>
    </div>
  );
};

export default UpdateHistory;

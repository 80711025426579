import React from 'react';
import EllipsisMenu from '../../components/EllipsisMenu';
import { Link, useNavigate } from 'react-router-dom';
import { DocumentTextIcon, UsersIcon } from '@heroicons/react/outline'; // Assuming you have Heroicons installed for icons

const JobCard = ({ job }) => {
    const navigate = useNavigate();

    const viewApplicants = (job) => {
        navigate(`/ats/applicants/${job._id}`, { state: { job } });
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col justify-between h-full">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">{job.title}</h2>
                <div className="space-x-2">
                    {/* Icons for actions */}
                    <EllipsisMenu
        isOpen={true} // Set isOpen to true for testing
       job={job}
        entryId={job._id}
      
      />
                </div>
            </div>
            
           
            <div className="grid grid-cols-2 gap-x-8 mb-4">
                <div>
                    <p className="text-gray-600 mb-2"><strong>Positions:</strong> {job.noOfPositions}</p>
                    <p className="text-gray-600 mb-2"><strong>Experience:</strong> {job.experience}</p>
                </div>
                <div>
                    <p className="text-gray-600 mb-2"><strong>Status:</strong> {job.statusUpdates.length > 0 ? job.statusUpdates[job.statusUpdates.length - 1] : 'N/A'}</p>
                    <p className="text-gray-600 mb-2"><strong>Latest History:</strong> {job.historyUpdates.length > 0 ? job.historyUpdates[job.historyUpdates.length - 1] : 'N/A'}</p>
                </div>
            </div>  <p className="text-gray-600 mb-2"><strong>Number of Candidates Applied:</strong> {job.candidateId.length}</p>
            <div className="mt-auto text-right">
                <Link to={`/ats/job/${job._id}`}>
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2">
                        View Details
                    </button>
                </Link>
                <button
                    className={`bg-blue-500 text-white px-4 py-2 rounded-md ${job?.candidateId?.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => viewApplicants(job)}
                    disabled={job?.candidateId?.length === 0}
                >
                    View Applicants
                </button>
            </div>
        </div>
    );
};

const JobList = ({jobData}) => {
    return (
        <div className="container mx-auto p-6">
       
        {jobData.length === 0 ? (
            <p className="text-center text-gray-600">There are no jobs created as of now.</p>
        ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {jobData.map((job) => (
                    <JobCard key={job._id} job={job} />
                ))}
            </div>
        )}
    </div>
    );
};

export default JobList;

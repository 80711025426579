import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Breadcrumb() {
  const [lastWord, setLastWord] = useState("");
  const location = useLocation();

  useEffect(() => {
    const url = window.location.href;
    const lastPath = url.split("/").filter(Boolean).pop(); // Filter removes empty strings
    const capitalizedLastWord =
      lastPath.charAt(0).toUpperCase() + lastPath.slice(1);
    setLastWord(capitalizedLastWord);
  }, [location]);
  const navigate = useNavigate();
  const handleHomeClick = () => {
    navigate("/dashboard");
  };
  return (
    <div className="mb-4 block text-gray-700 text-sm mb-2 font-bold center">
      <nav className="text-sm" aria-label="Breadcrumb">
        <ol className="list-none p-0 inline-flex">
          <li className="flex items-center">
            <a
             
              className="text-blue-500 hover:text-blue-700 hover:hand cursor-pointer"
              onClick={handleHomeClick} // This is your onClick event handler
            >
              Home
            </a>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3 w-3 mx-1 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </li>
          <li className="flex items-center">
            <span className="text-yellow-500">{lastWord}</span>
          </li>
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumb;

import axios from 'axios';
import weevaluateagent from './weevaluateagent';

const API_BASE_URL = '/ats/v1'; // Replace with your API base URL

const dashboardService = {
 

  // Get a Count by ID
  getCount: async (user) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/dashboard/counts`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

};

export default dashboardService;

import React from 'react';
import LoginButton from './LoginButton'; // Assuming you have a LoginButton component
 // Assuming you have an Image component for SVG images

const FeatureCard = ({ title, description }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-4">
    <h2 className="text-xl font-bold text-gray-800 mb-2">{title}</h2>
    <p className="text-gray-600">{description}</p>
  </div>
);

const LandingScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-4xl px-4 py-8">
        <div className="flex justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-800">
          Welcome to the WeEvaluate Partner Portal!
          </h1>
          <LoginButton />
        </div>
  
<p className="text-lg text-center text-gray-600 mb-8">
  Explore our powerful recruitment platform designed to simplify and enhance the hiring process. With WeEvaluate, you can access personalized interview solutions that revolutionize the way you evaluate candidates. Our advanced technology analyzes resumes and job descriptions to create tailored interview questions, ensuring a seamless and efficient recruitment experience. Join us and unlock the potential of modern hiring today!
</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <FeatureCard
            title="Personalized Interviews"
            description="Generate interview questions tailored to each candidate's job description and resume."
          />
          <FeatureCard
            title="Streamlined Process"
            description="Efficiently manage interviews, saving valuable time and resources for your team."
          />
          <FeatureCard
            title="Fair Evaluation"
            description="Provide a transparent and fair evaluation process for job seekers, ensuring equal opportunities."
          />
          <FeatureCard
            title="Reduced Bias"
            description="Minimize bias in hiring decisions by utilizing advanced technology and standardized evaluations."
          />
        
          
          {/* Include other FeatureCard components here */}
        </div>
        <div className="mt-8 text-center">
          <p className="text-sm text-gray-600">
            Learn more about WeEvaluate and start revolutionizing your recruitment process today.
          </p>
        </div>
        <div className="max-w-md mx-auto mt-8">
         
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;

import React, { useEffect, useState } from "react";
import candidateService from "../../service/candidateService";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import { useNavigate } from "react-router-dom";
import Table, {
  AvatarCell,
  SelectColumnFilter,
 } from "../../../../components/OTable/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faEye, faPassport, faReceipt } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../../components/Loading";
import HelpSection from "../../../../components/HelpSection";

const DisplayAllScheduledInterview = () => {
  const { user,isLoading, getAccessTokenSilently } = useAuth0();
  const [isDataLoaded,setIsDataLoaded] =useState(false);
  console.log(user.email);
  const navigate = useNavigate();
  const [scheduledInterviewsData, setSchedulesInterviewsData] = useState(null);
  const [activeTab, setActiveTab] = useState('steps');
  const [openTab, setOpenTab] = useState(null);

  const toggleTab = (index) => {
    if (openTab === index) {
      setOpenTab(null);
    } else {
      setOpenTab(index);
    }
  };
  const fetchScheduledInterviews = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log('DisplayAllScheduledInterview',token)
      superagent.setToken(token);
      const response = await candidateService.getScheduledInterviews(
        user.email
      );
      console.log("in displayAllScheduledInterviews ", response);
      if (response) {
        setSchedulesInterviewsData(response);
      }
    } catch (error) {
      console.error("Error while fetching scheduled interviews:", error);
    }
  };
  useEffect(() => {
    fetchScheduledInterviews();
    console.log("at 30", scheduledInterviewsData);
  }, []);

  const handleInterviewFinalReport = (item) => {
    console.log(item);
    navigate(`/interviewgenius/finalreport/${item.interview.jobId}/${item._id}`);
  };
  const handleInterviewTracker = (item) => {
    console.log(item);
    navigate(`/interviewgenius/tracker/${item.interview.jobId}/${item._id}`);
  };
  const handleInterviewReport = (item) => {
    console.log(item);
    navigate(`/interviewgenius/report/${item.interview.jobId}/${item._id}`);
  };
  const analysisList = (item) => {
    console.log(item);
    navigate(`/interviewgenius/analysisList`);
  };
  const verifyStatus =(statuses, targetStatus) => {
    // Check if the target status exists in the array
    for (let i = 0; i < statuses.length; i++) {
      if (statuses[i].status === targetStatus) {
        return true; // Status found
      }
    }
    return false; // Status not found
  }
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullName",
        Cell: AvatarCell,
        value: "A",
        imgAccessor: "imgUrl",
        nameAccessor: "fullName",
        emailAccessor: "email",
        width:'30%'
      },
      {
        Header: "Job Title",
        Filter: SelectColumnFilter,
        accessor: "jobTitle",
        width:'25%'
      },
      {
        Header: "Invitation Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        width:'15%'
      
      },

      {
        Header: "Invited on",
        accessor: "time",
        Cell: ({ row }) => (
          <>
            {
             new Date(row.original.updatedDate).toLocaleString()
            }
          </>
        ),
        width:'20%'
      },
      {
        Header: "...",
        accessor: "role",
        Cell: ({ row }) => (
          <>
            {
              <>
              <FontAwesomeIcon
              icon={faPassport}
              title="View"
              onClick={() =>  handleInterviewTracker(row.original)}
              className="text-green-500 ml-3"
            />
           {verifyStatus(row.original.interview.status,'Validation Completed') && <FontAwesomeIcon
              icon={faReceipt}
              title="View"
              onClick={() =>  handleInterviewReport(row.original)}
              className="text-blue-500 ml-3"
            />}
             {verifyStatus(row.original.interview.status,'Final Report Completed') &&<FontAwesomeIcon
              icon={faCertificate}
              title="View"
              onClick={() =>  handleInterviewFinalReport(row.original)}
              className="text-gray-500 ml-3"
            />}
            </>
            }
          </>
        ),
        width:'20%'
      },
    ],
    []
  );
const steps=[
  {
    title: "Step 1: Access Interview Genius",
    details: "Click the button 'Go to Interview Genius' below or use the sidebar link 'Interview Genius'."
  },
  {
    title: "Step 2: Start Candidate Analysis",
    details: "If the list of candidates is not shown, click on 'Start Analysis'."
  },
  {
    title: "Step 3: Add Job Listings",
    details: "If no list of jobs is available, click on 'Add Job'. You will see two tabs: 'Generate Job Description' and 'Upload Job'."
  },
  {
    title: "Step 4: Generate Job Description",
    details: "Provide job title, experience (years), and required skills. Click 'AI Generate Job Description' to generate."
  },
  {
    title: "Step 5: Review and Save Job Description",
    details: "After the job description is generated, click 'Save' or 'Edit' to modify details, then save again."
  },
  {
    title: "Step 6: Candidate Selection and Resume Upload",
    details: "Select a candidate or click 'Add Candidate' to upload a resume and add them to your list."
  },
  {
    title: "Step 7: Start Final Analysis",
    details: "Click 'Start Analysis' to evaluate candidate fit for the job."
  },
  {
    title: "Step 8: Generate Interview Questions",
    details: "Click 'Generate Question' to create interview questions."
  },
  {
    title: "Step 9: Send Invite to Candidate",
    details: "Finally, send an invite to the candidate."
  }
]
  return (
    <div>
      {!scheduledInterviewsData && <Loading/>}
      {scheduledInterviewsData && scheduledInterviewsData.length === 0 && (
          <div className="min-h-screen bg-gray-100 py-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
             
              <div className="px-4 py-5 sm:px-6">
  <h3 className="text-lg leading-6 font-medium text-gray-900"> Interview Scheduling Guide</h3>
  <div className="flex justify-between items-center">
    <p className="text-sm text-gray-500 max-w-xl">Detailed steps to guide you through scheduling an interview.</p>
    <button onClick={analysisList} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Go to Interview Genius
    </button>
  </div>
  </div>
              <div className="border-t border-gray-200">
             <HelpSection steps={steps}/>
              </div>
            </div>
          </div>
        </div>
      )}
      {scheduledInterviewsData && scheduledInterviewsData.length > 0 && (
       <div>
          <Table  style={{height:'300px'}}  columns={columns} data={scheduledInterviewsData} />
       
        </div>
      )}
    </div>
  );
};

export default DisplayAllScheduledInterview;

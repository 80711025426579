import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../services/weevaluateagent";
import jobService from "../../services/jobService";
import InlineButtonControl from "../../components/InlineButtonControl";
import { useParams } from "react-router-dom";
import SkillsTagInput from "../../components/SkillsTagInput";
import OTabs from "../../../../components/OTabs/OTabs";
import JobDescriptionForm from "../../../InterviewGenius/components/Job/JobDescriptionForm";
import UploadJobWithDropzone from "../../../InterviewGenius/components/Job/UploadJobWithDropzone";

function JobForm({ jobId }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();
  const [isExisted, setIsExisted] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isMainDetailsEdit, setIsMainDetailsEdit] = useState(false);

  const qualifications = [
    "IX",
    "Bachelor's Degree",
    "Master's Degree",
    "Ph.D.",
    "Other",
  ]; // Define qualification options
  const rolesAndResponsibilities = [""];

  const getDefaultDate = () => {
    const defaultDate = new Date("2024-05-19"); // Default date set to April 30, 2024
    return defaultDate.toISOString().split("T")[0]; // Format date as 'YYYY-MM-DD'
  };
  const [formData, setFormData] = useState({
    _id: "",
    title: "",
    experience: "",
    noOfPositions: "",
    skills: [],
    location: "",
    description: "",
    qualifications: [],
    salary: 0,
    minSalary: "",
    maxSalary: "",
    rolesAndResponsibilities: [],
    benefits: [],
    applicationDeadline: getDefaultDate(),
    hiringManager: "",
    company: {
      name: "",
      website: "",
    },
    contactEmail: "",
    jobpdf: "", // file upload field
    equalOpportunityStatement: "",
    createdBy: "",
    updateDate: "",
  });
  const initialOptions = [
    { value: "", label: "Select Location", disabled: true },
    { value: "OnSite", label: "OnSite" },
    { value: "Hybrid", label: "Hybrid" },
    { value: "Remote", label: "Remote" },
    // Add more options as needed
  ];
  const [locationOptions, setLocationOptions] = useState(initialOptions);
  const [skill, setSkill] = useState(formData?.skills || []);

  const handleSkillsChange = (newSkills) => {
    setFormData({
      ...formData,
      skills: newSkills,
    });
  };

  useEffect(() => {
    if (jobId) {
      console.log(" Job Id from props", jobId);
      setIsExisted(true);
      fetchJobData(jobId);
    }
    if (id) {
      console.log("Job Id", id);
      setIsExisted(true);
      fetchJobData(id);
    }
  }, [jobId, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.otherLocation && formData.otherLocation != "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        location: formData.otherLocation, // Reset location to empty string or previous value
      }));
    }
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await jobService.createJob(formData);
      setFormData(response);
      console.log("Job form data submitted successfully");
    } catch (error) {
      console.error("Error submitting Job form data:", error);
    }
  };

  const handleUpdate = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await jobService.updateJobById(id, formData);
      console.log("Job updated:", response);
    } catch (error) {
      console.error("Error updating Job:", error);
    }
  };

  const fetchJobData = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await jobService.getJobById(id);
      setFormData(response);
      console.log("Job form data: ", response);
    } catch (error) {
      console.error("Error updating Job form data:", error);
    }
  };

  const mainDetailsEdit = () => {
    setIsMainDetailsEdit(true);
  };

  const mainDetailsSave = () => {
    setIsMainDetailsEdit(false);
    handleUpdate(formData?._id);
  };
  const handleAddRole = () => {
    setFormData((prevState) => ({
      ...prevState,
      rolesAndResponsibilities: [...prevState.rolesAndResponsibilities, ""],
    }));
  };
  const handleAddRequirments = () => {
    setFormData((prevState) => ({
      ...prevState,
      qualifications: [...prevState.qualifications, ""],
    }));
  };
  const handleAddBenefits = () => {
    setFormData((prevState) => ({
      ...prevState,
      benefits: [...prevState.benefits, ""],
    }));
  };
  const handleListChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: prevFormData[name].map((item, i) => (i === index ? value : item)),
    }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateWebsite = (website) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(website);
  };
  const tabs = [
    {
      name: "tab1",
      label: "Generate Job Description",
      content: (
        <JobDescriptionForm
          setSelectedJob={setFormData}
          setIsExisted={setIsExisted}
        ></JobDescriptionForm>
      ),
    },
    {
      name: "tab2",
      label: "Upload Job Document",
      content: (
        <UploadJobWithDropzone
          setSelectedJob={setFormData}
          setIsExisted={setIsExisted}
        />
      ),
    },
  ];
  const handleRequirmentChange = (newRequirment) => {
    setFormData({
      ...formData,
      qualifications: newRequirment,
    });
  };
  const handleRemoveRequirment = (indexToRemove) => {
    const updatedRequirment = formData?.qualifications.filter(
      (_, index) => index !== indexToRemove
    );
    handleRequirmentChange(updatedRequirment);
  };
  const handleRolesResponsibilitiesChange = (newRolesResponsibilities) => {
    setFormData({
      ...formData,
      rolesAndResponsibilities: newRolesResponsibilities,
    });
  };
  const handleRemoveRolesResponsibilities = (indexToRemove) => {
    const updatedRolesResponsibilities =
      formData?.rolesAndResponsibilities.filter(
        (_, index) => index !== indexToRemove
      );
    handleRolesResponsibilitiesChange(updatedRolesResponsibilities);
  };

  const handleBenefitsChange = (newBenefits) => {
    setFormData({
      ...formData,
      benefits: newBenefits,
    });
  };
  const handleRemoveBenefits = (indexToRemove) => {
    const updatedBenefits = formData?.benefits.filter(
      (_, index) => index !== indexToRemove
    );
    handleBenefitsChange(updatedBenefits);
  };

  const handleJobHome = () => {
    navigate("/ats/jobInfo");
  };

  const isValidInput = (value, pattern) => {
    if (!pattern) return true; // No pattern provided, no validation needed
    const regex = new RegExp(pattern);
    return regex.test(value);
  };
  const handleLocationUpdate = (event) => {
    const { value, pattern } = event.target;

    if (isValidInput(value, eval(pattern))) {
      setLocationOptions((prevOptions) => [
        ...prevOptions,
        { value: value.trim(), label: value.trim() },
      ]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        location: value.trim(),
      }));
    }
  };
  return (
    <div>
      <div className="max-w-4xl mx-auto bg-white rounded p-8 shadow-md mb-20">
        <div className="flex justify-end ">
          {!isExisted && (
            <div className="flex items-center m-2">
              <input
                type="checkbox"
                id="manualCheckbox"
                checked={isManual}
                onChange={() => setIsManual(!isManual)}
                className="mr-2"
              />
              <label htmlFor="manualCheckbox" className="text-gray-700">
                <strong>Manual Entry</strong>
              </label>
            </div>
          )}
          <div className=" m-5 top-4 right-4 space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded-md"
              onClick={() => handleJobHome()}
            >
              Back to Job List
            </button>
          </div>
        </div>
        {!isManual && !jobId && !formData?._id && (
          <div className="max-w-4xl mx-auto bg-white rounded p-8 shadow-md">
            <OTabs tabs={tabs} />
          </div>
        )}
        {(isExisted || isManual) && (
          <div>
            <div style={{ float: "right" }}>
              {isExisted && (
                <InlineButtonControl
                  saveClick={mainDetailsSave}
                  editClick={mainDetailsEdit}
                />
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <div style={{ display: "none" }}>{formData?._id}</div>
                <label
                  htmlFor="title"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Title
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Job Title"
                    required
                  />
                ) : (
                  <div className="data-value">{formData?.title}</div>
                )}
              </div>

              <div className="mb-4">
                <div style={{ display: "none" }}>{formData?._id}</div>
                <label
                  htmlFor="noOfPositions"
                  className="block text-gray-700 font-bold mb-2"
                >
                  No Of Positions
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <input
                    type="text"
                    id="noOfPositions"
                    name="noOfPositions"
                    value={formData.noOfPositions}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="No Of Positions"
                    required
                  />
                ) : (
                  <div className="data-value">{formData?.noOfPositions}</div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-gray-700 font-bold mb-2"
              >
                Description
              </label>

              {!isExisted || isMainDetailsEdit ? (
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter Required Description"
                  required
                />
              ) : (
                <div className="data-value">{formData?.description}</div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="qualifications"
                className="block text-gray-700 font-bold mb-2"
              >
                Requirments
              </label>

              {!isExisted || isMainDetailsEdit ? (
                <div>
                  <button
                    style={{ float: "right" }}
                    onClick={handleAddRequirments}
                    className="flex items-center space-x-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-9V7a1 1 0 012 0v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2H7a1 1 0 010-2h2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Add Requirements</span>
                  </button>

                  <br></br>
                  {formData?.qualifications.map((qualification, index) => (
                    <div className="flex items-center space-x-1 m-2">
                      <input
                        key={index}
                        type="text"
                        name="qualifications"
                        value={qualification}
                        className="shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        onChange={(e) => handleListChange(e, index)}
                      />
                      <button
                        className="ml-1 text-red-500 cursor-pointer"
                        onClick={() => handleRemoveRequirment(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="data-value">
                  <ul>
                    {formData?.qualifications.map((qualification, index) => (
                      <li key={index}>
                        <label>{qualification}</label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="mb-4">
                <label
                  htmlFor="rolesAndResponsibilities"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Roles & Responsibilities
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <div>
                    <button
                      style={{ float: "right" }}
                      onClick={handleAddRole}
                      className="flex items-center space-x-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-9V7a1 1 0 012 0v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2H7a1 1 0 010-2h2z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>Add Role</span>
                    </button>
                    <br></br>
                    {formData?.rolesAndResponsibilities.map(
                      (rolesAndResponsibilitie, index) => (
                        <div className="flex items-center space-x-1 m-2">
                          <input
                            key={index}
                            type="text"
                            name="rolesAndResponsibilities"
                            value={rolesAndResponsibilitie}
                            className="shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            onChange={(e) => handleListChange(e, index)}
                          />
                          <button
                            className="ml-1 text-red-500 cursor-pointer"
                            onClick={() =>
                              handleRemoveRolesResponsibilities(index)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className="data-value">
                    <ul>
                      {formData?.rolesAndResponsibilities.map(
                        (rolesAndResponsibility, index) => (
                          <li key={index}>
                            <label>{rolesAndResponsibility}</label>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="skills"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Skills
                </label>
                {!isExisted || isMainDetailsEdit ? (
                  <SkillsTagInput
                    value={formData.skills}
                    onChange={handleSkillsChange}
                  />
                ) : (
                  <div className="data-value">
                    {formData?.skills.join(", ")}
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <label
                  htmlFor="experience"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Experience
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <input
                    type="text"
                    id="experience"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Required Experience"
                    required
                  />
                ) : (
                  <div className="data-value">{formData?.experience}</div>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="location"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Location
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <div>
                    {formData.location !== "Other" ? (
                      <select
                        id="locationDropdown"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        {locationOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                          >
                            {option.label}
                          </option>
                        ))}
                        {formData.location &&
                          locationOptions.findIndex(
                            (opt) => opt.value === formData.location
                          ) === -1 && (
                            <option value={formData.location}>
                              {formData.location}
                            </option>
                          )}{" "}
                        <option value="Other">Other</option>
                        {/* Add more options as needed */}
                      </select>
                    ) : null}

                    {formData.location === "Other" && (
                      <div className="flex items-center">
                        <input
                          type="text"
                          id="otherLocationInput"
                          name="otherLocation"
                          value={formData.otherLocation}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleLocationUpdate(e);
                            }
                          }}
                          onBlur={(e) => {
                            handleLocationUpdate(e);
                          }}
                          pattern="/^[a-zA-Z0-9 ]+$/"
                          title="Only alphabets and numbers are allowed"
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            !isValidInput(formData.otherLocation, /^[a-zA-Z0-9 ]+$/) ? 'border-red-500' : ''
                          }`}
                           placeholder="Enter Other Location"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            // Handle canceling 'Other' selection
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              location: "", // Reset location to empty string or previous value
                              otherLocation: "", // Clear otherLocation input
                            }));
                          }}
                          className="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="data-value">{formData?.location}</div>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="salary"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Salary Range In LPA
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <input
                        type="number"
                        id="minSalary"
                        name="minSalary"
                        value={formData.minSalary}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Minimum Salary"
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        id="maxSalary"
                        name="maxSalary"
                        value={formData.maxSalary}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Maximum Salary"
                        required
                      />
                    </div>
                  </div>
                ) : (
                  <div className="data-value">
                    {formData?.minSalary} to {formData?.maxSalary}
                  </div>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="applicationDeadLine"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Application DeadLine
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <input
                    type="date"
                    id="applicationDeadLine"
                    name="applicationDeadLine"
                    value={formData.applicationDeadline}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Select Deadline"
                  />
                ) : (
                  <div className="data-value">
                    {formData?.applicationDeadline}
                  </div>
                )}
              </div>

              <div className="mb-4" style={{ display: "none" }}>
                <label
                  htmlFor="hiringManager"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Hiring Manager
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <select
                    id="hiringManager"
                    name="hiringManager"
                    value={formData.hiringManager}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  >
                    <option value="">Select Hiring Manager</option>
                    <option value="Manager 1">Manager 1</option>
                    <option value="Manager 2">Manager 2</option>
                    {/* Add more options as needed */}
                  </select>
                ) : (
                  <div className="data-value">{formData?.hiringManager}</div>
                )}
              </div>
              <div className="mb-4" style={{ display: "none" }}>
                <label
                  htmlFor="contactEmail"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Contact Email Id:
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <input
                    type="text"
                    id="contactEmail"
                    name="contactEmail"
                    value={formData.contactEmail}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Contact Email"
                    required
                  />
                ) : (
                  <div className="data-value">{formData?.contactEmail}</div>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="benefits"
                  className="block text-gray-700 font-bold mb-2"
                >
                 Benefits
                </label>

                {!isExisted || isMainDetailsEdit ? (
                  <div>
                    <button
                      style={{ float: "right" }}
                      onClick={handleAddBenefits}
                      className="flex items-center space-x-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-9V7a1 1 0 012 0v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2H7a1 1 0 010-2h2z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>Add Benefits</span>
                    </button>
                    <br></br>
                    {formData?.benefits.map(
                      (benefit, index) => (
                        <div className="flex items-center space-x-1 m-2">
                          <input
                            key={index}
                            type="text"
                            name="benefits"
                            value={benefit}
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                              !isValidInput(benefit, /^[a-zA-Z0-9 ]+$/) ? 'border-red-500' : ''
                            }`}  onChange={(e) => handleListChange(e, index)}
                          />
                          <button
                            className="ml-1 text-red-500 cursor-pointer"
                            onClick={() =>
                              handleRemoveBenefits(index)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className="data-value">
                    <ul>
                      {formData?.benefits.map(
                        (benefit, index) => (
                          <li key={index}>
                            <label>{benefit}</label>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </div>

              <div className="flex justify-center">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                    isExisted ? "hidden" : ""
                  }`}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default JobForm;

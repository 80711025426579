import React, { useState,useEffect } from 'react';
import Table, { AvatarCell, SelectColumnFilter, StatusPill } from '../../components/OTable/Table'; // new

const TeamMemberForm = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const columns = React.useMemo(() => [
      {
        Header: "Name",
        accessor: 'name',
        Cell: AvatarCell,
        imgAccessor: "imgUrl",
        emailAccessor: "email",
      },
      {
        Header: "Title",
        accessor: 'title',
      },
      {
        Header: "Status",
        accessor: 'status',
        
      },
      {
        Header: "Age",
        accessor: 'age',
      },
      {
        Header: "Role",
        accessor: 'role',
        Filter: SelectColumnFilter,  // new
        filter: 'includes',
      },
    ], [])
  

    

 const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    photo: '',
    teamRole: ''
  };

  const [formData, setFormData] = useState(initialFormData);

 

  const fetchTeamMembers = async () => {
    try {
      const response = await fetch('http://localhost:3001/api/ats/v1/team/');
      const data = await response.json();
      console.log(data);
      setTeamMembers(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
        const response = await fetch('http://localhost:3001/api/ats/v1/userprofile/', {
          method: 'POST',
          
          headers: {
            'Content-Type': 'application/json'
  
          },
          
          body: JSON.stringify(formData)
          
        });
        if (response.ok) {
         
          console.log('Team saved successfully');
          setFormData(initialFormData); // Reset form data to initial state
          // Optionally, you can reset the form here
          fetchTeamMembers();
        } else {
          console.error('Failed to save Team');
        }
      } catch (error) {
        console.error('Error saving Team:', error);
      }
      
  };
  useEffect(() => {
    fetchTeamMembers();
  }, []);
  return (
    <div>
    <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        

      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
            First Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="firstName"
            type="text"
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
            Last Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="lastName"
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactNumber">
            Contact Number
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="contactNumber"
            type="tel"
            placeholder="Contact Number"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="photo">
            Photo
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="photo"
            type="file"
            name="photo"
            onChange={handleChange}
          />
        </div>

         {/* Second Column */}
  <div>
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="teamRole">
        Team Role
      </label>
      <select
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="teamRole"
        name="teamRole"
        value={formData.teamRole}
        onChange={handleChange}
        required
      >
        <option value="">Select Team Role</option>
        <option value="Manager">Manager</option>
        <option value="Developer">Developer</option>
        <option value="Designer">Designer</option>
        <option value="Tester">Tester</option>
      </select>
    </div>

    

  </div>

  {/* Submit button */}
  <div className="col-span-2 flex items-center justify-center mt-6">
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      type="submit"
    >
      Add Team Member
    </button>
  </div>
      </form>
      
    </div>
    <h2 className="text-2xl font-bold mb-4 text-black">Team Members</h2>
     <Table  style={{height:'300px'}} columns={columns} data={teamMembers} />
         <table  style={{height:'300px'}} className="w-full border-collapse border border-black-400">
  <thead className="bg-yellow-300">
    <tr>
      <th className="py-3 px-6 border border-gray-400 text-left font-semibold text-black">First Name</th>
      <th className="py-3 px-6 border border-gray-400 text-left font-semibold text-black">Last Name</th>
      <th className="py-3 px-6 border border-gray-400 text-left font-semibold text-black">Email</th>
      <th className="py-3 px-6 border border-gray-400 text-left font-semibold text-black">Contact Number</th>
      <th className="py-3 px-6 border border-gray-400 text-left font-semibold text-black">Team Role</th>
      {/* Add more table headers for additional fields if needed */}
    </tr>
  </thead>
  <tbody className="bg-blue-100">
    {teamMembers.map(member => (
      <tr key={member._id} className="border-b border-gray-400">
        <td className="py-4 px-6 border border-gray-400 text-black">{member.firstName}</td>
        <td className="py-4 px-6 border border-gray-400 text-black">{member.lastName}</td>
        <td className="py-4 px-6 border border-gray-400 text-black">{member.email}</td>
        <td className="py-4 px-6 border border-gray-400 text-black">{member.contactNumber}</td>
        <td className="py-4 px-6 border border-gray-400 text-black">{member.teamRole}</td>
        {/* Add more table cells for additional fields if needed */}
      </tr>
    ))}
  </tbody>
</table>
    </div>
  );
};

export default TeamMemberForm;

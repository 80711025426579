import React, { useState } from 'react';

const Tab = ({ label, isActive, onClick }) => {
  return (
    <button
      type="button"
      className={`font-semibold py-3 px-4 focus:outline-none ${isActive ? 'bg-blue-500 text-white' : 'bg-gray-500 text-white'}`}
      onClick={onClick}
      style={{ flex: 1 }}
    >
      {label}
    </button>
  );
};

const QuestionDetailsTab = ({ selectedQuestion,initial=false }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="overflow-auto h-80 mb-8">
       {initial && <>
      <div className="border-gray-200 ">
        <div className="flex flex-wrap">
          <div className="w-full  flex">
            <Tab
              label="Question"
              isActive={activeTab === 0}
              onClick={() => handleTabClick(0)}
            />
            <Tab
              label="Expected explanation"
              isActive={activeTab === 1}
              onClick={() => handleTabClick(1)}
            />
           
          </div>
        </div>
      </div>
     
      <div className="p-4">
        {activeTab === 0 && (
          <p>{selectedQuestion?.question || "Select a question to view details"}</p>
        )}
        {activeTab === 1 && (
          <p>{selectedQuestion?.explanation || "-"}</p>
        )}
       
      
      </div>
      </>}
     {!initial && <>
      <div className="border-gray-200 ">
        <div className="flex flex-wrap">
          <div className="w-full  flex">
            <Tab
              label="Question"
              isActive={activeTab === 0}
              onClick={() => handleTabClick(0)}
            />
            <Tab
              label="Answer "
              isActive={activeTab === 1}
              onClick={() => handleTabClick(1)}
            />
            <Tab
              label="Score"
              isActive={activeTab === 2}
              onClick={() => handleTabClick(2)}
            />
          </div>
        </div>
      </div>
      <div className="border-b border-gray-200 mb-4">
        <div className="flex flex-wrap">
          <div className="w-full  flex">
            <Tab
              label="Analysis"
              isActive={activeTab === 3}
              onClick={() => handleTabClick(3)}
            />
            <Tab
              label="Positive"
              isActive={activeTab === 4}
              onClick={() => handleTabClick(4)}
            />
            <Tab
              label="Negative"
              isActive={activeTab === 5}
              onClick={() => handleTabClick(5)}
            />
          </div>
        </div>
      </div>
      <div className="p-4">
        {activeTab === 0 && (
          <p>{selectedQuestion?.question || "Select a question to view details"}</p>
        )}
        {activeTab === 1 && (
          <p>{selectedQuestion?.candidateAnswer || "-"}</p>
        )}
        {activeTab === 2 && (
          <p>{selectedQuestion?.result?.score || "-"}</p>
        )}
        {activeTab === 3 && (
          <p>{selectedQuestion?.result?.analysis || "-"}</p>
        )}
        {activeTab === 4 && (
          <ul>
            {selectedQuestion?.result?.positiveObservations?.map((observation, index) => (
              <li key={index}>{observation}</li>
            ))}
          </ul>
        )}
        {activeTab === 5 && (
          <ul>
            {selectedQuestion?.result?.negativeObservations?.map((observation, index) => (
              <li key={index}>{observation}</li>
            ))}
          </ul>
        )}
      </div>
      </>}
    </div>
  );
};

export default QuestionDetailsTab;

import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

// Sample static data for demonstration. In a real application, you might pass this as props or fetch from an API


const RecentActivities = ({activities}) => {
    const { user} = useAuth0();
    console.log(activities)
    return (
        <div className="mt-10 bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-semibold mb-4">Recent Activities</h2>
            <div className="overflow-y-auto" style={{ maxHeight: '300px' }}>
            <ul>
            {activities.map(activity => {
                const date = new Date(activity.createdAt);
                const day = date.getDate();
                const month = date.getMonth() + 1; // January is 0, so add 1
                const year = date.getFullYear();

                // Pad day and month with leading zeros if needed
                const formattedDay = day < 10 ? '0' + day : day;
                const formattedMonth = month < 10 ? '0' + month : month;

                const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

                // Replace occurrences of loginName in activity.details with 'you'
                const replacedDetails = activity.details.replace(new RegExp(user.name, 'gi'), 'You');

                return (
                    <li key={activity._id}>
                       
                        {replacedDetails} on {formattedDate}
                    </li>
                );
            })}
        </ul>
            </div>
        </div>
    );
};

export default RecentActivities;

// src/store/chatSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatHistory: [],
  error: null
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.chatHistory.push(action.payload);
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { addMessage, setError } = chatSlice.actions;

export const selectChatHistory = state => state.chat.chatHistory;
export const selectError = state => state.chat.error;

export default chatSlice.reducer;

import React from 'react';
import {useNavigate } from 'react-router-dom';

const CreateAssessment = () => {
    const navigate = useNavigate();
    const handleGenerateAssessment =() =>{
        navigate("/crewevaluator/generateAssessment");
     }
  return (
    <div className="container mx-auto py-8">
     
      {/* Form for creating assessment */}
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 grid grid-cols-2 gap-4">
 
        {/* Teacher selection */}

        <div className="mb-4">
          <label htmlFor="teacherSelect" className="block text-gray-700 text-sm font-bold mb-2">
            Select Teacher
          </label>
          <select id="teacherSelect" className="form-select mt-1 block w-full">
            <option value="" disabled selected>Select Teacher</option>
            {/* Populate options dynamically */}
            <option value="teacher1">Teacher 1</option>
            <option value="teacher2">Teacher 2</option>
            <option value="teacher3">Teacher 3</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="assessmentName" className="block text-gray-700 text-sm font-bold mb-2">
            Assessment Name
          </label>
          <input id="assessmentName" type="text" className="form-input mt-1 block w-full" placeholder="AssessMent Name" />
        </div>
        {/* Assessment Date selection */}
        <div className="mb-4">
          <label htmlFor="assessmentDate" className="block text-gray-700 text-sm font-bold mb-2">
            Assessment Date
          </label>
          <input id="assessmentDate" type="date" className="form-input mt-1 block w-full" placeholder="Select Date" />
        </div>
        {/* Syllabus/Concept selection */}
        <div className="mb-4">
          <label htmlFor="syllabusSelect" className="block text-gray-700 text-sm font-bold mb-2">
            Select Syllabus/Concept
          </label>
          <select id="syllabusSelect" className="form-select mt-1 block w-full">
            <option value="" disabled selected>Select Syllabus/Concept</option>
            {/* Populate options dynamically */}
            <option value="math">Mathematics - Algebra</option>
            <option value="science">Science - Biology</option>
            <option value="english">English - Literature</option>
            {/* Add more options as needed */}
          </select>
        </div>
        {/* Due Date selection */}
        <div className="mb-4">
          <label htmlFor="dueDate" className="block text-gray-700 text-sm font-bold mb-2">
            Due Date
          </label>
          <input id="dueDate" type="date" className="form-input mt-1 block w-full" placeholder="Select Due Date" />
        </div>
        {/* Assignment Time selection */}
        <div className="mb-4">
          <label htmlFor="assignmentTime" className="block text-gray-700 text-sm font-bold mb-2">
            Assignment Time (hours)
          </label>
          <select id="assignmentTime" className="form-select mt-1 block w-full">
            <option value="" disabled selected>Select Assignment Time</option>
            <option value="1">1 hour</option>
            <option value="2">2 hours</option>
            <option value="3">3 hours</option>
            {/* Add more options as needed */}
          </select>
        </div>
        {/* Number of Questions selection */}
        <div className="mb-4">
          <label htmlFor="numberOfQuestions" className="block text-gray-700 text-sm font-bold mb-2">
            Number of Questions
          </label>
          <select id="numberOfQuestions" className="form-select mt-1 block w-full">
            <option value="" disabled selected>Select Number of Questions</option>
            <option value="5">5 questions</option>
            <option value="10">10 questions</option>
            <option value="15">15 questions</option>
            {/* Add more options as needed */}
          </select>
        </div>
        {/* Reviewer Details selection */}
        <div className="mb-4 ">
          <label htmlFor="reviewerDetails" className="block text-gray-700 text-sm font-bold mb-2">
            Reviewer 
          </label>
          <select id="reviewerDetails" className="form-select mt-1 block w-full">
            <option value="" disabled selected>Select Reviewer </option>
            <option value="5">Reviewer  1</option>
            <option value="10">Reviewer  2</option>
            <option value="15">Reviewer  3</option>
            {/* Add more options as needed */}
          </select>
           </div>
        {/* Generate button */}
        <div className="col-span-2 flex justify-end">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button"  onClick={() => handleGenerateAssessment() }>
            Generate Assessment
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateAssessment;

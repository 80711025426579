// GeneratedQuestions.js
import React, { useEffect, useState } from "react";
import "./GeneratedQuestions.css"; // Make sure to create a corresponding CSS file
import { useNavigate, useParams } from "react-router-dom";
import candidateService from "../../service/candidateService";
import jobService from "../../service/jobService";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import request from "superagent";
import Table from "../../../../components/OTable/Table";
import AILoading from "../../../../components/AILoading";
import OTabs from "../../../../components/OTabs/OTabs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuestionDetailsTab from "../DisplayAllScheduledInterview/QuestionDetailsTab";

function GeneratedQuestions() {
  const { user, getAccessTokenSilently } = useAuth0();
  // Replace with your actual data or props
  const mailServer = "https://beta.weevaluate.ai";
  const params = useParams();
  const navigate = useNavigate();
  const [questionsData, setQuestionsData] = useState("");
  const [jobQuestions,setJobQuestions] = useState("")
  const [selectedQuestionObject, setSelectedQuestionObject] = useState(0);
  const [overallData, setOverallData] = useState({});
  const [invitation, setInvitation] = useState(false);
  const [isQuestionGenerated, setQuestionGenerated] = useState(false);
  const [isJobQuestionGenerated, setJobQuestionGenerated] = useState(false);
  const [finalQuestionList, setFinalQuestionList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({
    candidate: [],
    job: [],
  });
  useEffect(() => {
    fetchGeneratedQuestion();
  }, [params]);
  const fetchGeneratedQuestion = async () => {
    const token = await getAccessTokenSilently();
    superagent.setToken(token);

    candidateService
      .generateInterviewQuestions(params.jobId, params.candidateId) // Replace with the actual job ID and candidate ID
      .then((response) => {
        // setQuestions(response);
        setQuestionsData(response);
        setQuestionGenerated(true);
        console.log(questionsData);
        if(response?.interview?.finalQuestionList){
        setFinalQuestionList(response?.interview?.finalQuestionList)
        }
      })
      .catch((error) => {
        console.error("Error analyzing candidate suitability:", error);
        setQuestionGenerated(false);
      });

      jobService
      .generateJobQuestions(params.jobId) // Replace with the actual job ID and candidate ID
      .then((response) => {
        // setQuestions(response);
        setJobQuestions(response);
        setJobQuestionGenerated(true);
        console.log(jobQuestions);
      })
      .catch((error) => {
        console.error("Error getting  job questions:", error);
        setJobQuestionGenerated(false);
      });
  };

  function generateTokenJSON(jobId, candidateId) {
    const data = { jobId, candidateId };
    console.log(data);
    const encodedData = btoa(JSON.stringify(data)); // Base64 encoding
    const tokenBase = crypto.randomUUID();
    console.log(tokenBase + "$" + encodedData);
    return tokenBase + "$" + encodedData;
  }
  const link = generateTokenJSON(params.jobId, params.candidateId);
  const getBrowserDetails = () => {
    const { userAgent, language, cookiesEnabled } = navigator;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const details = {
      userAgent: userAgent,

      language: language,
      cookiesEnabled: cookiesEnabled,
      screenWidth: screenWidth,
      screenHeight: screenHeight,
    };

    return JSON.stringify(details);
  };
  const sendInvitation = async () => {
    console.log("I am in sendInvitation");
    const code = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
    const browserDetails = getBrowserDetails();
    console.log(browserDetails);
    console.log("finalQuestionList", finalQuestionList);
    setInvitation(true);
    try {
      const invitationSaved = await candidateService.sendInvitation(
        params.jobId,
        params.candidateId,
        browserDetails,
        code,
        finalQuestionList
      );

      const mailsResponse = sendEmail(code);
      if (mailsResponse) {
        toast.success("Interview invitation sent successfully!", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate(`/interviewgenius/scheduleinterviews`);
      }
    } catch (err) {
      if (
        err.status === 400 &&
        err.response &&
        err.response.body &&
        err.response.body.error
      ) {
        // If the error is a 400 Bad Request and contains an error message
        const errorMessage = err.response.body.error;
        // Display the error message to the user
        toast.error(errorMessage, {
          position: "top-right",
          draggable: true,
          autoClose: 3000, // Close the toast after 3 seconds
        });
      }
    }
  };
  const sendEmail = async (code) => {
    console.log(user);
    const data = {
      candidate_name: questionsData?.fullName,
      candidate_email: questionsData?.email,
      job_title: questionsData?.title,
      interview_email: user.email,
      company_name: "Onyeg",
      interview_link: "https://interview.weevaluate.ai/",
      interview_code: code,
    };

    request
      .post(`/mails/invitation.php`)
      .send(data)
      .set("Content-Type", "application/json")
      .then((response) => {
        // setResponseMessage(response.text);
        console.log("Email sent successfully:", response.text);
        return response.text;
      })
      .catch((error) => {
        console.error("Error sending email:", error.message);
        throw error;
      });
  };
  const isEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === "object" && typeof val2 === "object") {
        if (!isEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  };

  const fnSelected = (type, obj, event) => {
    const id = obj.qid;
    if (event.target.checked) {
      // Checkbox is checked
      setCheckedItems((prevItems) => ({
        ...prevItems,
        [type]: [...prevItems[type], id],
      }));
      obj.type = type;
      toggleFinalQuestionList(obj);
    } else {
      // Checkbox is unchecked
      setCheckedItems((prevItems) => ({
        ...prevItems,
        [type]: prevItems[type].filter((itemId) => itemId !== id),
      }));

      // toggleFinalQuestionList(obj);
      setFinalQuestionList((prevList) =>
        removeDuplicateQuestion(prevList, obj)
      );
    }
  };
  const removeDuplicateQuestion = (questionList, objToRemove) => {
    return questionList.filter((item) => !isEqual(item, objToRemove));
  };
  const toggleFinalQuestionList = (obj) => {
    const isExisting = finalQuestionList.some((item) => isEqual(item, obj));

    if (!isExisting) {
      // If the object does not exist in the finalQuestionList, add it
      setFinalQuestionList((prevList) => [...prevList, obj]);
    } else {
      // If the object exists in the finalQuestionList, remove it
      setFinalQuestionList((prevList) =>
        prevList.filter((item) => !isEqual(item, obj))
      );
    }
  };

  const candidateColumns = React.useMemo(
    () => [
      {
        Header: "Candidate Resume Based Questions",
        accessor: "question",
        Cell: ({ row }) => (
          <div>
            <div>
              <p
                className=""
                onClick={() => setSelectedQuestionObject(row.original)}
              >
                <input
                  type="checkbox"
                  name="cqid"
                  value={row.original.qui}
                  className="mr-4"
                  onChange={(event) =>
                    fnSelected("candidate", row.original, event)
                  }
                ></input>
                {row.original.question}
              </p>
            </div>
          </div>
        ),
      },
    ],
    []
  );
  const jobColumns = React.useMemo(
    () => [
      {
        Header: "Job Description Based Quesion",
        accessor: "question",
        Cell: ({ row }) => (
          <div>
            <div>
              <p
                className=""
                onClick={() => setSelectedQuestionObject(row.original)}
              >
                <input
                  type="checkbox"
                  name="jqid"
                  value={row.original.qui}
                  className="mr-4"
                  onChange={(event) => fnSelected("job", row.original, event)}
                ></input>
                {row.original.question}
              </p>
            </div>
          </div>
        ),
      },
    ],
    []
  );
  const finalColumns = React.useMemo(
    () => [
      {
        Header: "Final Questions",
        accessor: "question",
        Cell: ({ row }) => (
          <div  s>
            <div>
              <p
             tyle={{
              width: '70%', // Assuming an average character width of 2px
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
                className=""
                onClick={() => setSelectedQuestionObject(row.original)}
              >
                {row.original.question}
              </p>
            </div>
          </div>
        ),
      },
    ],
    []
  );
  const tabs = [
    {
      name: "tab1",
      label: "Candidate based Questions",
      content: (
        <Table
         style={{height:'300px'}}
          columns={candidateColumns}
          data={questionsData?.question?.data}
        />
      ),
    },
    {
      name: "tab2",
      label: "Job Based Question",
      content: (
        <Table style={{height:'300px'}} columns={jobColumns} data={jobQuestions} />
      ),
    },
  ];
  const handleBackButtonClick = () => {
    navigate(`/interviewgenius/analysis/${params.jobId}/${params.candidateId}`);
  };
  const verifyStatus =(statuses, targetStatus) => {
    // Check if the target status exists in the array
    if(!statuses)
    return false
    for (let i = 0; i < statuses.length; i++) {
      if (statuses[i].status === targetStatus) {
        return true; // Status found
      }
    }
    return false; // Status not found
  }
  return (
    <>
      {isQuestionGenerated && isJobQuestionGenerated ? (
        <div className="">
          {questionsData && jobQuestions && (
            <>
              {/* Job Title and Candidate Name */}
              <div className="bg-white p-4 rounded-lg shadow-md mb-2 flex justify-between items-center">
                <div>
                  <h2 className="text-lg font-semibold mb-2">
                    Interview Questions for {questionsData.fullName}
                  </h2>
                  <p>Job Title: {questionsData.title}</p>
                </div>
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                  onClick={handleBackButtonClick}
                >
                  Back to Candidate Analysis
                </button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
              { !verifyStatus(questionsData?.interview?.status,'Invitation Sent') &&  <div className="column questions">
                  <OTabs tabs={tabs} />
                </div>}

                <>
                  <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                    <Table
                    style={{height:'300px',width:'50%'}}
                      columns={finalColumns}
                      data={finalQuestionList}
                      html={
                        <>
                          {" "}
                          {finalQuestionList.length > 0 && !verifyStatus(questionsData?.interview?.status,'Invitation Sent') && (
                            <input
                              type="button"
                              value="Send Invitation"
                              className={`py-2 px-4 rounded font-bold ${
                                invitation
                                  ? "bg-gray-400 text-gray-600 cursor-not-allowed"
                                  : "bg-blue-500 hover:bg-blue-600 text-white hover:text-white"
                              }`}
                              disabled={invitation}
                              onClick={sendInvitation}
                            />
                          )}
                        </>
                      }
                    />
                  </div>
                </>
                { verifyStatus(questionsData?.interview?.status,'Invitation Sent') && <div className="bg-white  rounded shadow overflow-auto h-100">
                <QuestionDetailsTab selectedQuestion={selectedQuestionObject}  initial={true}/>
                </div>
                }
              </div>
            </>
          )}
        </div>
      ) : (
        <AILoading text="The AI-powered 'WeEvaluate' is generating questions. Please wait a few minutes; it'll be quick"></AILoading>
      )}
    </>
  );
}

export default GeneratedQuestions;

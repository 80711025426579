// CandidateReport.js
import React, { useEffect, useState } from 'react';
import './CandidateReport.css';
import candidateService from '../../service/candidateService';
 // Import your service here
 // Make sure to create a corresponding CSS file
 import { useNavigate  ,useParams } from "react-router-dom";
function CandidateReport() {
  // Replace with your actual data or props
  const [reportData, setReportData] = useState(null); // State to store report data
  const params = useParams();
  useEffect(() => {
    // Function to fetch report data
    const jobId =  params.jobId; 
    const candidateId = params.candidateId; 
      candidateService
      .generateReport( jobId, candidateId) // Replace with the actual job ID and candidate ID
      .then((response) => {
       setReportData(response.report.data)
      })
      .catch((error) => {
        console.error("Error Reporting Data:", error);
      });
    // Call the fetchReportData function when the component mounts
    
  }, []); // Empty dependency array ensures this effect runs once on component mount

  if (!reportData) {
    return <p>Loading...</p>; // You can add a loading indicator here
  }
  return (
    <div className="candidate-report">
      <h4>Final Report</h4>
      <div className="report-container">
        <div className="report-section strength">
          <h3>Strength</h3>
          <p>{reportData.strength}</p>
        </div>
        <div className="report-section ">
          <h3>Overall Performance</h3>
          <p>{reportData.overall_performance}</p>
        </div>
        <div className="report-section weakness">
          <h3>Weakness</h3>
          <p>{reportData.weakness}</p>
        </div>
        <div className="report-section ">
          <h3>Interiewer Feedabck</h3>
          <p><textarea cols={100} rows={8}></textarea></p>
        </div>
        <div className="report-section ">
          <h3>Overall Rating</h3>
          <p>{reportData.score}</p>
        </div>
        <div className="report-section ">
          <h3>Submit Report</h3>
          <p><button>Send</button></p>
        </div>
        
      </div>
    </div>
  );
}

export default CandidateReport;

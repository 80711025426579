import React from 'react';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Accordion({ title, children, isAccordionOpen, toggleAccordion }) {
    return (
      <div className="border border-gray-200 rounded mb-4">
        <div className="flex justify-between p-4 cursor-pointer" onClick={toggleAccordion}>
          <h3 className="text-lg font-semibold">{title}</h3>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} className="w-6 h-6 text-gray-500 mr-2" />
        </div>
  
        {isAccordionOpen && (
          <div className="p-4">
            {children}
          </div>
        )}
      </div>
    );
  }
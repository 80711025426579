import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EmailEditorDialog = ({ open, onClose, body , email , subject , ccmail,pdfBlob }) => {
  const [recipient, setRecipient] = useState(email);
  const [editorSubject, setEditorSubject] = useState(subject);
  const [editorData, setEditorData] = useState(body);
  useEffect(() => {
    if(body){
     setRecipient(email)
     setEditorSubject(subject)
     setEditorData(body)
    }
   }, [body,  email , subject , ccmail,pdfBlob ]);

  const handleSend = () => {
    // Implement your send email logic here, e.g., call an API
    console.log('Sending email:', { recipient, subject, editorData });
    const formData = new FormData();
    formData.append('recipient', recipient);
    formData.append('cc', ccmail); // Include CC recipients
    formData.append('subject', subject);
    formData.append('message', editorData);
    if(pdfBlob)
    formData.append('pdf', pdfBlob, 'report.pdf'); 

    // Make POST request to PHP script
    fetch('https://partner.weevaluate.ai/mails/report_email.php', {
      method: 'POST',
      body: formData,
    })
    .then(response => {
      console.log(response)
      response.text()})
    .then(data => {
      console.log(data);
      onClose(); // Close the dialog after sending
    })
    .catch(error => {
      console.error('Error:', error);
    });
 
    // Close the dialog after sending
    onClose();
  };

  return (
    <div className={`fixed inset-0 ${open ? 'block' : 'hidden'}`} style={{ background: 'rgba(0,0,0,0.5)' }}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded">
        <h2 className="text-xl mb-4">New Email</h2>
        <input
        readOnly="true"
          type="text"
          placeholder="To"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
          className="w-full mb-4 border border-gray-300 rounded px-3 py-2"
        />
        <input
          type="text"
          readOnly="true"
          placeholder="Subject"
          value={editorSubject}
          onChange={(e) => setEditorSubject(e.target.value)}
          className="w-full mb-4 border border-gray-300 rounded px-3 py-2"
        />
        <div className="mb-4 border border-gray-300 rounded">
          <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditorData(data);
            }}
          />
        </div>
        <div className="text-right">
          <button onClick={handleSend} className="mr-2 px-4 py-2 bg-blue-500 text-white rounded">Send</button>
          <button onClick={onClose} className="px-4 py-2 bg-gray-300 text-gray-700 rounded">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EmailEditorDialog;

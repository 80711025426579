import weevaluateagent from './weevaluateagent';

const API_BASE_URL = '/ats/v1'; // Replace with your API base URL

const userService = {
 
  // Create a new user
  createUser: async (userData) => {
    try {
      console.log("in userService", userData)
      const response = await weevaluateagent.requestsA.post(`${API_BASE_URL}/user/`, userData);
      return response;
    } catch (error) {
      throw error
    }
  },

  // Get all candidates
  CheckShortNameAvailability: async (shortName) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/user/check-short-name?shortName=${shortName}`)
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Get a candidate by ID
  getUserById: async (userId) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/user/${userId}`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Update a candidate by ID
  updateUserById: async (userId, updatedUserData) => {
    try {
      const response = await weevaluateagent.requestsA.put(`${API_BASE_URL}/user/${userId}`, updatedUserData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },


  checkCompanyProfileEmail:async (email) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/user/check-company-profile?email=${email}`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },
  confirmClientCodeByEmail:async (code) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/user/confirm-client-code?code=${code}`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },
  // Delete a candidate by ID
  deleteUserById: async (userId) => {
    try {
      const response = await weevaluateagent.requestsA.del(`${API_BASE_URL}/user/${userId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },
};

export default userService;

// Dashboard.js
import React, { useEffect, useState } from "react";

import RecentActivities from "./components/RecentActivities";
import InterviewTable from "./components/InterviewTable";
import StatsCard from "./components/StatsCard";
import InsightsChart from "./components/InsightsChart";
import superagent from './service/weevaluateagent'
import dashboardService from "./service/dashboardService";
import { useAuth0 } from '@auth0/auth0-react';
const Dashboard = () => {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const [counts, setCounts] = useState({
    jobCount: 0,
    candidateCount: 0,
    invitationSentCount: 0,
    recentActivites:[]
  });

  // Fetch counts from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isAuthenticated) return;

        const token = await getAccessTokenSilently();
        superagent.setToken(token);
        const response = await dashboardService.getCount(user)
        const data = response
       
        setCounts(data);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchData();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  return (
    <div className="bg-white-100 p-5">
      <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8 py-12 ">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <StatsCard title="Candidates" value={counts.candidateCount} />
          <StatsCard title="Jobs" value={counts.jobCount} />
          <StatsCard title="Interviews Scheduled" value={counts.invitationSentCount} />
         
        </div>
        <div className="mb-6" style={{display:'none'}}>
          <InterviewTable />
        </div>
        <div className="mb-6" style={{display:'none'}}>
          <InsightsChart />
        </div>
        <div className="mb-6">
          {counts?.recentActivites && <RecentActivities activities={counts.recentActivites}/>}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

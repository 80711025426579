// candidateService.js

import axios from "axios";

import weevaluateagent from "./weevaluateagent";

const API_BASE_URL = "/interviewgenius/v1"; // Replace with your API base URL

const candidateService = {
  // Create a new candidate
  
  createCandidate: async (candidateData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/candidates`,
        candidateData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAllCandidates: async () => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates`
      );

      return response;
    } catch (error) {
      throw error;
    }
  },

  // Get a specific candidate by ID
  getCandidateById: async (candidateId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/candidates/${candidateId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Update a candidate by ID
  updateCandidateById: async (candidateId, candidateData) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/candidates/${candidateId}`,
        candidateData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Delete a candidate by ID
  deleteCandidateById: async (candidateId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/candidates/${candidateId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Upload resume for a candidate by ID
  uploadResume: async (file, user) => {
    try {
      const formData = new FormData();
      formData.append("user", user);
      formData.append("resume", file);
      console.log(formData);
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/upload-resume`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      //console.log("error in status ",error.status);
      //console.log("error in response",error.message);
      throw error;
    }
  },

  // Analyze candidate suitability for a job
  analyzeCandidateSuitability: async (jobId, candidateId) => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/${jobId}/${candidateId}/analyze-suitability`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  interviewValidation: async (jobId, candidateId) => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/${jobId}/${candidateId}/interview-validation`
      );
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },
  interviewFinalReport: async (jobId, candidateId) => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/${jobId}/${candidateId}/interview-final-report`
      );
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },
  updateAnalysisData: async (candidateId, jobId, analysisData) => {
    try {
      const response = await weevaluateagent.requestsA.put(
        `${API_BASE_URL}/candidates/${candidateId}/analysis/${jobId}`,
        { analysisData }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Generate personalized interview questions
  generateInterviewQuestions: async (jobId, candidateId) => {
    try {
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/generate-interview-questions`,
        {
          jobId,
          candidateId,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Store question rating and observation for a candidate
  sendInvitation: async (jobId, candidateId, browserDetails, code,finalQuestionList) => {
    try {
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/${candidateId}/interviews/${jobId}`,
        {
          jobId,
          candidateId,
          browserDetails: browserDetails,
          code,
          finalQuestionList
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Store question rating and observation for a candidate
  storeQuestionRating: async (jobId, candidateId, dataToUpdate) => {
    try {
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/store-question-rating`,
        {
          jobId,
          candidateId,
          dataToUpdate,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Generate a report based on candidate ratings
  generateReport: async (jobId, candidateId) => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/${jobId}/${candidateId}/generate-report`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Generate All scheduled interviews
  getScheduledInterviews: async (user) => {
    console.log(user);
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/${user}/all-interviews`
      );
      console.log("in candidate service ", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  getAnalysisList: async (user) => {
    console.log(user);
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/${user}/analysisList`
      );
      console.log("in candidate service ", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  signedURL: async (key) => {
    console.log(key);
    try {
      const response = await weevaluateagent.requestsA.post(
        `/getSignedURL`,{key:key}
      );
      console.log("in candidate service ", response);
      return response;
    } catch (error) {
      throw error;
    }
  },
  awsConfig: async () => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `/aws-config`
      );
      console.log("in candidate service ", response);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default candidateService;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage, setError, selectChatHistory, selectError } from './store/chatSlice';
import chatBotService from './services/chatBotService';
import superagent from "./services/weevaluateagent";
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faRobot } from '@fortawesome/free-solid-svg-icons';
const ChatBot= () => {
  const {isLoading, isAuthenticated, user,getAccessTokenSilently} = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const chatHistory = useSelector(selectChatHistory);
  const error = useSelector(selectError);
  const [message, setMessage] = useState('');
  const toggleChatWindow = () => {
    setIsOpen(!isOpen);
    setError('');
  };

  const closeChatWindow = () => {
    setIsOpen(false);
    setError('');
  };


  const sendMessage = async () => {
    if (!message.trim()) return;
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      dispatch(addMessage({ text: message, sender: 'user' }));
      const response=await chatBotService.createChat({ text: message });
      //const response = await axios.post('http://localhost:5000/api/chat/send', { text: message });
      console.log('chatBotService',response)
      dispatch(addMessage({ text: response.message, sender: 'bot' }));
      setError(false);
      setMessage('');
    } catch (error) {
      dispatch(setError('An error occurred while sending the message.'));
    }
  };

  return (
    <>
      <div className={`fixed bottom-10 right-10 z-50  p-4 ${isOpen ? 'hidden' : ''}`} id="bubble" onClick={toggleChatWindow}>
        <div className="bg-blue-500 text-white rounded-full p-4 cursor-pointer shadow-lg">
          <p>Adhu <FontAwesomeIcon icon={faRobot}></FontAwesomeIcon>!</p>
        </div>
      </div>

      <div className={`fixed bottom-1 right-10 w-200 h-400 ${!isOpen ? 'hidden' : ''}  bg-gray-100 border-l border-gray-300 shadow-lg transform transition duration-300 ${isOpen ? '' : 'translate-x-full'}`} id="chatWindow">
        <div className="flex justify-between bg-blue-500 text-white p-4">
            <h3>Hi I'm a Adhu</h3>
          <span className="cursor-pointer" id="closeChat" onClick={closeChatWindow}>&times;</span>
        </div>
        <div className="overflow-y-auto h-400 p-4">
        {chatHistory.map((message, index) => (
          <div key={index} className={`mb-4 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
            <div className={`max-w-xs mx-auto p-3 rounded-lg ${message.sender === 'user' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'}`}>
              {message.sender === 'user' ? message.text + ': You' : 'Adhu: ' + message.text}
            </div>
          </div>
        ))}
        <div className="flex items-center mt-8">
          <input
            type="text"
            value={message}
            onChange={e => setMessage(e.target.value)}
            className="flex-1 mr-4 px-4 py-2 rounded-md border border-gray-300"
          />
          <button
            onClick={sendMessage}
            className="px-6 py-2 rounded-md bg-blue-500 text-white cursor-pointer"
          >
            Send <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon>
          </button>
        </div>
        {error && <div className="text-red-500 mt-8">{error}</div>}
        </div>
      </div>
    </>
  );
};

export default ChatBot;

import React from 'react';
import './AILoading.css'; // Assuming you will create a separate CSS file for styles

const AILoading = ({text,type=''}) => {
  return (
    <div className={`loading-container ${type ? `loading-container-${type}` : ''}`}>
   
        <p>Loading...</p>
      <div className="loading-spinner-inner" style={{ maxWidth: '250px' }}>
 {text}
</div> <div className="loading-spinner"></div>
     
    
    </div>
  );
};

export default AILoading;

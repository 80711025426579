import axios from 'axios';
import weevaluateagent from './weevaluateagent';

const API_BASE_URL = '/ats/v1'; // Replace with your API base URL

const jobService = {
  uploadJobDescriptionPDF: async (file,user) => {
    try {
      const formData = new FormData();
      formData.append('pdfFile', file);
      formData.append('user',user);

      const response = await weevaluateagent.requestsA.post(`${API_BASE_URL}/jobs/upload-pdf`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  // Create a new job
  createJob: async (jobData) => {
    try {
      console.log("in jobService", jobData)
      const response = await weevaluateagent.requestsA.post(`${API_BASE_URL}/jobs/`, jobData);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Get all jobs
  getAllJobs: async (token) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/jobs`)
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Get a job by ID
  getJobById: async (jobId) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/jobs/${jobId}`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Update a job by ID
  updateJobById: async (jobId, updatedJobData) => {
    try {
      const response =  await weevaluateagent.requestsA.put(`${API_BASE_URL}/jobs/${jobId}`, updatedJobData);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Delete a job by ID
  deleteJobById: async (jobId) => {
    try {
      const response = await weevaluateagent.requestsA.delete(`${API_BASE_URL}/jobs/${jobId}`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },


getApplicantsByJob: async(job) => {
  try {
    console.log(job)
    const response = await weevaluateagent.requestsA.post(`${API_BASE_URL}/jobs/candidates`, { "candidateIds": job.candidateId}, {
      headers: {
        'Content-Type': 'application/json',
      },
    } );
    return response;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }

}
};


export default jobService;

import React from 'react';
import { useNavigate } from 'react-router-dom';

const GenerateAssessment = () => {
    const navigate = useNavigate();
    const handleNotifyCrew =() =>{
        navigate("/crewevaluator/assessmentList");
     }
    return (
        <div className="">
            {/* Header */}
            <header className="bg-white py-4 shadow-md">
                <div className="container mx-auto">
                    {/* Details of the assessment */}
                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                            <p className="text-sm text-gray-600">Assessment Name:</p>
                            <p className="font-bold">Sample Assessment</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Employee:</p>
                            <p className="font-bold">John Doe</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Assessment Date:</p>
                            <p className="font-bold">May 15, 2024</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Reviewer:</p>
                            <p className="font-bold text-green-500">Ram</p>
                        </div>
                    </div>
                </div>
            </header>

            {/* Main Content */}
            <div className="container mx-auto py-8">
                {/* Question List */}
                <div className="bg-white shadow-md rounded p-4 mb-8">
                    <h2 className="text-lg font-bold mb-4">Question List</h2>
                    <div className="divide-y divide-gray-200">
                        {/* Sample Questions */}
                        <div className="py-2">
                            <h3 className="text-lg font-semibold">Question 1</h3>
                            <p className="text-gray-600">Details about the question 1</p>
                        </div>
                        <div className="py-2">
                            <h3 className="text-lg font-semibold">Question 2</h3>
                            <p className="text-gray-600">Details about the question 2</p>
                        </div>
                        <div className="py-2">
                            <h3 className="text-lg font-semibold">Question 3</h3>
                            <p className="text-gray-600">Details about the question 3</p>
                        </div>
                        {/* Add more questions as needed */}
                    </div>
                </div>

                {/* Notify to Crew Button */}
                <div className="flex justify-end">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleNotifyCrew()}>Notify to Crew</button>
                </div>
            </div>
        </div>
    );
};

export default GenerateAssessment;

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import jobService from '../../service/jobService';
import { toast } from 'react-toastify';
// Import your candidate service


const UploadJobWithDropzone = ({setSelectedJob, setIsExisted = () => {} }) => {
 
  const { getAccessTokenSilently,user } = useAuth0();
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
     
      toast.error('To proceed, please upload a Job Description file. Job description is missing.', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
       });
      return;
    }

    const file = acceptedFiles[0]; // Assume only one file is dropped

    console.log(file.name);
    const acceptedFileTypes = ['pdf','docx']
    const ext =  file.name.split('.').pop();
    console.log(ext);
    if(acceptedFileTypes.indexOf(ext) === -1){
      
      toast.error('Upload a Job Description file in PDF or DOCX format, please.', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
       });
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await jobService.uploadJobDescriptionPDF(file,user.email);
      console.log(response)
      setSelectedJob(response)
      if (typeof setIsExisted === 'function') {
        setIsExisted(true);
    }
      toast.success( 'WeEvaluate successfully extracted job   details from '+file.name, {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // You can add further logic or navigate to another page upon successful upload
    } catch (error) {
      console.error('Error uploading resume:', error);
      // Handle the error as needed
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    
    <div className=" w-full mb-6"> {/* Added mb-10 class */}
    <div
      {...getRootProps()}
      className={`dropzone bg-white border border-gray-300 rounded-lg shadow-md p-10  ${
        isDragActive ? 'active' : ''
      }`}
    >
      <input {...getInputProps()} />
      <p style={{fontWeight:'bold',textAlign:'center'}}>Drag & drop a job file here, or click to select a file</p>
    </div>
  </div>
  
  );
};

export default UploadJobWithDropzone;

import React, { useEffect, useState } from "react";
import candidateService from "../../service/candidateService";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import { useNavigate } from "react-router-dom";
import Table, {
  AvatarCell,
  SelectColumnFilter,
 } from "../../../../components/OTable/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faTable } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../../components/Loading";
import HelpSection from "../../../../components/HelpSection";

const AnalysisList = () => {
  const { user,isLoading, getAccessTokenSilently } = useAuth0();
  const [isDataLoaded,setIsDataLoaded] =useState(false);
  console.log(user.email);
  const navigate = useNavigate();
  const [analysisList, setAnalysisList] = useState(null);
  const [openTab, setOpenTab] = useState(null);

  const toggleTab = (index) => {
    if (openTab === index) {
      setOpenTab(null);
    } else {
      setOpenTab(index);
    }
  };
  const fetchAnalysisList = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log('DisplayAllScheduledInterview',token)
      superagent.setToken(token);
      const response = await candidateService.getAnalysisList(
        user.email
      );
      console.log("in displayAllAnalysisList ", response);
      if (response) {
        setAnalysisList(response);
      }
    } catch (error) {
      console.error("Error while fetching scheduled interviews:", error);
    }
  };
  useEffect(() => {
    fetchAnalysisList();
    console.log("at 30", analysisList);
  }, []);

  const showAnalysis = (item) => {
    console.log(item);
    navigate(`/interviewgenius/analysis/${item.analysisResults.jobId}/${item._id}`);
  };
  
  const handleQuestions = (item) => {
    console.log(item);
    navigate(`/interviewgenius/questions/${item.analysisResults.jobId}/${item._id}`);
  };
  const newAnalysis = () => {
   
    navigate(`/interviewgenius/selectcandidate`);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullName",
        Cell: AvatarCell,
        value: "A",
        imgAccessor: "imgUrl",
        nameAccessor: "fullName",
        emailAccessor: "email",
        width:'40%'
      },
      {
        Header: "Job Title",
        Filter: SelectColumnFilter,
        accessor: "jobTitle",
        width:'20%',
      },
      {
        Header: "Fit",
        accessor: "status",
        width:'20%',
        Cell: ({ row }) => (
          <>
            {
             row?.original?.analysisResults?.data?.fitChart.data[0] +'%'
            }
          </>
        ),
      
      },
      {
        Header: "Action",
        accessor: "role",
        width:'20%',
        Cell: ({ row }) => (
          <>
            {
              <FontAwesomeIcon
              icon={faTable}
              title="Show Analysis"
              onClick={() =>  showAnalysis(row.original)}
              className="text-green-500 ml-6"
            />
          
      
            }
            { row.original.questionResults.length>0 && <FontAwesomeIcon
              icon={faQuestion}
              title="Show Questions"
              onClick={() =>  handleQuestions(row.original)}
              className="text-red-500 ml-6"
            />}
          </>
        ),
      },
    ],
    []
  );
  const steps = [
    {
      title: "Step 1: Start Candidate Analysis",
      details: "If the list of candidates is not shown, click on 'Start Analysis'."
    },
    {
      title: "Step 2: Add Job Listings",
      details: "If no list of jobs is available, click on 'Add Job'. You will see two tabs: 'Generate Job Description' and 'Upload Job'."
    },
    {
      title: "Step 3: Generate Job Description",
      details: "Provide job title, experience (years), and required skills. Click 'AI Generate Job Description' to generate."
    },
    {
      title: "Step 4: Review and Save Job Description",
      details: "After the job description is generated, click 'Save' or 'Edit' to modify details, then save again."
    },
    {
      title: "Step 5: Candidate Selection and Resume Upload",
      details: "Select a candidate or click 'Add Candidate' to upload a resume and add them to your list."
    },
    {
      title: "Step 6: Start Final Analysis",
      details: "Click 'Start Analysis' to evaluate candidate fit for the job."
    },
    {
      title: "Step 7: Generate Interview Questions",
      details: "Click 'Generate Question' to create interview questions."
    },
    {
      title: "Step 8: Send Invite to Candidate",
      details: "Finally, send an invite to the candidate."
    }
  ];
  return (
    <div >
      {!analysisList && <Loading/>}
      {analysisList && analysisList.length === 0 && (
        <div className="min-h-screen bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
  <h3 className="text-lg leading-6 font-medium text-gray-900"> Candidate Analysis Guide</h3>
  <div className="flex justify-between items-center">
    <p className="text-sm text-gray-500 max-w-xl">Detailed steps to guide you through how to do candidate analysis.</p>
    <button onClick={newAnalysis} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Start Analysis
    </button>
  </div>
</div>
<HelpSection steps={steps}/>
          </div>
        </div>
      </div>
      )}
      {analysisList && analysisList.length > 0 && (
        <div  >
           <Table  style={{height:'300px'}} columns={columns} data={analysisList}   html={  <button onClick={newAnalysis} className="bg-blue-500 z-1 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      New Analysis
      </button>}/>
       
       
        </div>
      )}
    </div>
  );
};

export default AnalysisList;

import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { registerables } from 'chart.js';

// Register Chart components (including scales)
Chart.register(...registerables);

const SkillExperienceChart = ({skillExperienceChart}) => {
  const data = {
    labels: skillExperienceChart?.labels,
    datasets: [
      {
        label: 'Experience',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
        hoverBorderColor: 'rgba(75, 192, 192, 1)',
        data: skillExperienceChart?.data,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Skills',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Experience',
        },
      },
    },
  };

  return (
    <div>
      <h4>Skill Experience Chart</h4>
      <Bar data={data} options={options} height={250} />
    </div>
  );
};

export default SkillExperienceChart;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import candidateService from "../../service/candidateService";
import AILoading from "../../../../components/AILoading";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import EmailEditorDialog from "../../../../components/EmailEditorDialog";


function InterviewFinalReport() {

  const { user, getAccessTokenSilently } = useAuth0();

  const [isReportLoading, setIsReportLoading] = useState(true);
  const [interviewFinalReport ,setInterviewFinalReport]=useState(null)
  const [data ,setData]=useState(null)
  const params = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // State variable to control the dialog box visibility
  const [body,setBody]=useState(null)
  const [email,setEmail]=useState(null)
  const [subject,setSubject]=useState(null)
  const [pdfBlob,setPdfBlob] = useState(false)
  useEffect(() => {
    fetchInterviewFinalReport();
  }, [params, getAccessTokenSilently]);
  const fetchInterviewFinalReport = async () => {
    const token = await getAccessTokenSilently();
    superagent.setToken(token);

    candidateService
      .interviewFinalReport(params.jobId, params.candidateId) // Replace with the actual job ID and candidate ID
      .then((response) => {
        console.log(response);
        setIsReportLoading(false)
        setInterviewFinalReport(response)
        setData(response)
      })
      .catch((error) => {
        console.error("Error in Fetching InterviewData:", error);
      });
  };


  const handleOpen = () => {
    setOpen(true); // Function to open the dialog box
  };

  const handleClose = () => {
    setOpen(false); // Function to close the dialog box
  };
  
  const handleDownload = async (download) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
  
    // Define colors
    const sectionHeaderColor = '#007bff'; // Blue color for section headers
    const borderColor = '#e0e0e0'; // Light gray color for border lines
  
    // Helper function to calculate content height
    const calculateContentHeight = (textArray) => {
      const lineHeight = 6; // Font size 6
      let contentHeight = 0;
      textArray.forEach((text) => {
        const lines = doc.splitTextToSize(text, doc.internal.pageSize.width - 40);
        contentHeight += lines.length * lineHeight;
      });
      return contentHeight;
    };
  
    // Add content to the PDF
    const addContent = (contentArray) => {
      let yPosition = 5;
      let pageIndex = 1;
  
      contentArray.forEach((content) => {
        const height = calculateContentHeight(content);
        if (yPosition + height > doc.internal.pageSize.height) {
          doc.addPage();
          yPosition = 5;
          pageIndex++;
        }
  
        // Draw section header with color
        doc.setFillColor(sectionHeaderColor);
        doc.setTextColor('#fff'); // White text color
        doc.rect(5, yPosition, doc.internal.pageSize.width - 7, 7, 'F'); // Draw filled rectangle
        yPosition += 6; // Adjust Y position for text
        content.forEach((line) => {
          doc.setFontSize(8);
      
        const lines = doc.splitTextToSize(line, doc.internal.pageSize.width - 40);
        lines.forEach((textLine) => {
          doc.text(10, yPosition, textLine); // Adjust X position for text
          doc.setTextColor('#000');
          yPosition += 6; 
        });
         // Font size 6
        });
  
        yPosition += 5; // Add padding between sections
      });
  
      return pageIndex;
    };
  
    // Define content sections
    const contentSections = [
      [`Title: ${data?.title}`],
      [`Full Name: ${data.fullName}`],
      [`Email: ${data.email}`],
      [`Overall Performance: ${data.finalReport.overall_performance}`],
      ['Points to Hire:', ...data.finalReport.points_to_hire],
      ['Points to Reject:', ...data.finalReport.points_to_reject],
      ['Suitable Skills:', ...data.finalReport.suitable_skills],
      ['Suitable Roles:', ...data.finalReport.suitable_roles],
      ['Behavior Observed:', ...data.finalReport.behavior_observed],
      ['Recommendations:', ...data.finalReport.recommendations],
      ['Summary:', data.finalReport.summary],
    ];
  
    // Add content to the PDF
    const totalPages = addContent(contentSections);
  
    // Save the PDF
    if(download){
  
      doc.save(`${data.fullName.trim()}_final_report_${totalPages}_pages.pdf`);
    }else{
      const pdfBlobObj = doc.output('blob');

      reportMail(pdfBlobObj)
    }
    
   
  };
  


const handleFeedback = async () => {
  setBody(interviewFinalReport?.finalReport?.feedback)
  setEmail(interviewFinalReport?.email);
  setSubject(`${interviewFinalReport?.fullName} feedback for ${interviewFinalReport?.title} interview`)
  handleOpen()
  
}
const reportMail = async (pdfBlobObj) => {
  setBody(generateEmailBody(user?.given_name,interviewFinalReport?.fullName))
  setEmail(user?.email);
  setSubject(`Report for ${interviewFinalReport?.fullName}  for ${interviewFinalReport?.title} interview`)
  setPdfBlob(pdfBlobObj)
  handleOpen()
}
const goToInvitations = async () => {
    navigate(`/interviewgenius/scheduleinterviews`);
}

function generateEmailBody(recipientName, candidateName ) {
  return `Dear ${recipientName},<br/>

I hope this email finds you well.<br/>

I am writing to inform you that I am attaching the report of <b>${candidateName}</b>. Please find the attached report for your review.
<br/><br/><br/><b>Summary:</b><br/><br/>

${interviewFinalReport?.finalReport?.summary} <br/><br/>

Best regards,
`;
}
  if (isReportLoading) {
    return (
      <AILoading text="WeEvaluate AI is currently generating Final Report for the  candidate interview. Please wait a moment." />
    );
  }
  return (
    <div className="container mx-auto  bg-gray-200 p-5 mb-20" id="finalPDF">
      <div className="mb-4 flex justify-end">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2" onClick={() => goToInvitations()}>
        Invitations
        </button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2" onClick={() => handleDownload(true)}>
        Download Report
        </button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2" onClick={() => handleFeedback()}>
        Send Feedback
        </button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2 mr-0" onClick={() => handleDownload(false)}>
        Send Mail
        </button>
        <EmailEditorDialog open={open} onClose={handleClose}  body={body} email={email} subject={subject} ccmail={user.email} pdfBlob={pdfBlob}/>
      </div>


      {/* Candidate Details */}
      <div className="w-full  mb-6">
        <section className="bg-white rounded-lg shadow p-6">
          <div className="flex mt-4">
            <div className="w-1/2 pr-2">
              <p>
                <strong>Name : </strong>
                {interviewFinalReport?.fullName}
              </p>
              <p>
                <strong>Email:</strong> {interviewFinalReport?.email}
              </p>
            </div>
            <div className="w-1/2 pl-2">
              <p>
                <strong>Phone:</strong>  {interviewFinalReport?.phone}
              </p>
              <p>
                <strong>Position:</strong> {interviewFinalReport?.title}
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Evaluation Metrics */}
      <div className="bg-white p-5 rounded-lg shadow-lg mb-3">
        <h2 className="text-2xl font-bold text-gray-700 mb-4">Evaluation Metrics</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="font-semibold text-gray-600">Points to Hire (
            {interviewFinalReport?.finalReport?.points_to_hire?.length}/{interviewFinalReport?.finalReport?.points_to_hire?.length+interviewFinalReport?.finalReport?.points_to_reject?.length})</h3>
            <ul className="list-disc pl-5 text-gray-700 overflow-auto h-40">
            {interviewFinalReport?.finalReport?.points_to_hire?.map((hire, index) => (
               <li  key={index} className="flex py-1 items-center">{hire}</li>
            ))}
            </ul>
          </div>
          <div>
            <h3 className="font-semibold text-gray-600">Points to Reject (
            {interviewFinalReport?.finalReport?.points_to_reject?.length}/{interviewFinalReport?.finalReport?.points_to_hire?.length+interviewFinalReport?.finalReport?.points_to_reject?.length})</h3>
            <ul className="list-disc pl-5 text-gray-700 overflow-auto h-40">
            {interviewFinalReport?.finalReport?.points_to_reject?.map((reject, index) => (
               <li  key={index} className="flex py-1 items-center">{reject}</li>
            ))}
            </ul>
          </div>
        </div>
        <p className="mt-4"><strong>Overall Performance:</strong> {interviewFinalReport?.finalReport?.overall_performance}</p>
      </div>

      {/* Skills and Roles */}
      <div className="bg-white p-5 rounded-lg shadow-lg mb-3 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 className="text-2xl font-bold text-gray-700 mb-4">Suitable Skills</h2>
          <ul className="list-disc pl-5 text-gray-700 overflow-auto h-40">
          {interviewFinalReport?.finalReport?.suitable_skills?.map((suitable_skill, index) => (
               <li  key={index} className="flex py-1 items-center">{suitable_skill}</li>
            ))}
          </ul>
        </div>
        <div>
          <h2 className="text-2xl font-bold text-gray-700 mb-4">Suitable Roles</h2>
          <ul className="list-disc pl-5 text-gray-700 overflow-auto h-40">
          {interviewFinalReport?.finalReport?.suitable_roles?.map((suitable_role, index) => (
               <li  key={index} className="flex py-1 items-center">{suitable_role}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* Behavior and Recommendations */}
      <div className="bg-white p-5 rounded-lg shadow-lg mb-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h2 className="text-2xl font-bold text-gray-700 mb-4">Behavior Observed</h2>
            <ul className="list-disc pl-5 text-gray-700 overflow-auto h-40">
          {interviewFinalReport?.finalReport?.behavior_observed?.map((behavior, index) => (
               <li  key={index} className="flex py-1 items-center">{behavior}</li>
            ))}
          </ul>
          </div>
          <div>
            <h2 className="text-2xl font-bold text-gray-700 mb-4">Recommendations</h2>
            <ul className="list-disc pl-5 text-gray-700 overflow-auto h-40">
          {interviewFinalReport?.finalReport?.recommendations?.length && interviewFinalReport?.finalReport?.recommendations?.map((recommendation, index) => (
               <li  key={index} className="flex py-1 items-center">{recommendation}</li>
            ))}
          </ul>
          </div>
        </div>
      </div>

      {/* Summary */}
      <div className="bg-white p-5 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-700 mb-4">Summary</h2>
        <p>{interviewFinalReport?.finalReport?.summary}</p>
      </div>
    </div>
  );
}

export default InterviewFinalReport;

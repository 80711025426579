import React from 'react';
import { useNavigate } from 'react-router-dom';

const ListOfConcepts = () => {
    const navigate = useNavigate();
    const handleCreateConcept = () => {
        navigate("/crewevaluator/createConcept");
    };
    const concepts = [
        { name: 'Concept 1', subject: 'Mathematics', grade: 'Grade 1', file: 'concept1.pdf' },
        { name: 'Concept 2', subject: 'Science', grade: 'Grade 2', file: 'concept2.pdf' },
        { name: 'Concept 3', subject: 'English', grade: 'Grade 3', file: 'concept3.pdf' }
      ];
      
    return (
        <div className="container mx-auto py-8">
            <div className="mb-4">
                <button onClick={handleCreateConcept} className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Create Concept</button>
            </div>
            <div className="bg-white shadow-md rounded p-4 mb-8">
                {concepts?.map((concept, index) => (
                    <div key={index} className="py-2 border-b border-gray-200">
                        <h3 className="text-lg font-semibold">{concept.name}</h3>
                        <p className="text-gray-600">{concept.subject} - {concept.grade}</p>
                        <p className="text-gray-600">File: {concept.file}</p>
                    </div>
                ))}
                {concepts?.length === 0 && <p>No concepts found.</p>}
            </div>
        </div>
    );
};

export default ListOfConcepts;

import React from 'react';
import Modal from 'react-modal';
import { XIcon } from '@heroicons/react/outline'; // Assuming you have the Heroicons package installed for icons

Modal.setAppElement('#root'); // Set the root element for accessibility

const PDFViewerDialog = ({ isOpen, onClose, pdfUrl }) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="modal h-screen-3/4">
            <div className="flex justify-between items-center px-4 py-2 bg-gray-200">
                <h2 className="text-lg font-semibold">PDF Viewer</h2>
                <button onClick={onClose} className="text-gray-600 hover:text-gray-900">
                    <XIcon className="h-6 w-6" />
                </button>
            </div>
            <div className="bg-white p-4 ">
                <iframe
                    title="PDF Viewer"
                    src={pdfUrl}
                    className="w-full h-screen m-4"
                    
                ></iframe>
            </div>
        </Modal>
    );
};

export default PDFViewerDialog;

import axios from "axios";
import weevaluateagent from "./weevaluateagent";

const API_BASE_URL = "/interviewgenius/v1"; // Replace with your API base URL

const jobService = {
  uploadJobDescriptionPDF: async (file, user) => {
    try {
      const formData = new FormData();
      formData.append("pdfFile", file);
      formData.append("user", user);

      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/jobs/upload-pdf`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  },

  JobDescriptionByAI: async (jobTitle, requiredSkills, experience, user) => {
    console.log(jobTitle, requiredSkills, experience);
    try {
      const formData = new FormData();
      formData.append("jobTitle", jobTitle);
      formData.append("requiredSkills", requiredSkills);
      formData.append("experience", experience);
      formData.append("user", user);

      // console.log(formData);

      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/jobs/jobdesc-ai`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  },

// Get job interview questions
  generateJobQuestions: async(jobId) => {
    
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/jobs/jobquestions/${jobId}`,
       );
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Create a new job
  createJob: async (jobData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/jobs`, jobData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Get all jobs
  getAllJobs: async (token) => {
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/jobs`
      );
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Get a job by ID
  getJobById: async (jobId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/job/${jobId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Update a job by ID
  updateJobById: async (jobId, updatedJobData) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/jobs/${jobId}`,
        updatedJobData
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Delete a job by ID
  deleteJobById: async (jobId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/jobs/${jobId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

};

export default jobService ;


import React, { useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
function InsightsChart() {
    useEffect(() => {
        const ctx1 = document.getElementById('interviewScoresChart').getContext('2d');
        const interviewScoresChart = new Chart(ctx1, {
          type: 'bar',
          data: {
            labels: ['Analysis', 'Interviews', 'Hire', 'Feedback'],
            datasets: [{
              label: 'Statistics',
              data: [8, 7, 9, 10],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
              ],
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
    
        const ctx2 = document.getElementById('interviewerRatingsChart').getContext('2d');
        const interviewerRatingsChart = new Chart(ctx2, {
          type: 'line',
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
            datasets: [{
              label: 'Interviews ',
              data: [4.5, 4, 4.8, 4.3, 4.6],
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }]
          }
        });
      }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6 mb-2">
         
          <div className=" p-6 bg-white shadow rounded">
            <h2 className="text-xl font-semibold ">This Month Statistics</h2>
            <canvas id="interviewScoresChart"></canvas>
          </div>
          <div className=" p-6 bg-white shadow rounded">
          <h2 className="text-xl font-semibold ">Overall Statistics</h2>
            <canvas id="interviewerRatingsChart"></canvas>
          </div>
        </div>
  );
}

export default InsightsChart;

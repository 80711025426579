import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navigation = ({ menuItems,showSidebar }) => {
  return (
    <div>
      <nav>
        <ul className="space-y-4">
          {menuItems.map((menuItem, index) => (
            <li key={index} className="flex items-center text-gray-700 hover:text-blue-500">
             <Link to={menuItem.path}> <FontAwesomeIcon icon={menuItem.icon} />
              {!showSidebar && <>{menuItem.label}</>}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;

import React, { useEffect, useState } from "react";
import jobService from "../../service/jobService";
import superagent from "../../service/weevaluateagent";
import { useAuth0 } from "@auth0/auth0-react";
import Table, { AvatarCell } from "../../../../components/OTable/Table";
import Loading from "../../../../components/Loading";
import JobDescriptionForm from "./JobDescriptionForm";
import UploadJobWithDropzone from "./UploadJobWithDropzone";
import OTabs from "../../../../components/OTabs/OTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";

// Import the job service

function JobsList({ onSelectJob }) {
  const { getAccessTokenSilently } = useAuth0();
  const [jobs, setJobs] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    //setSelectedJob(se);
    setShowModal(false);
  };
  async function fetchJobs() {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const jobsData = await jobService.getAllJobs(token);
      console.log(jobsData);
      setJobs(jobsData);
      setIsDataLoaded(true);
    } catch (error) {
      setJobs([]);
      console.error("Error fetching jobs:", error);
    }
  }
  useEffect(() => {
    // Fetch all jobs when the component mounts

    fetchJobs();
  }, [getAccessTokenSilently]);
  useEffect(() => {
    if (showModal) {
      fetchJobs();
    }
  }, [selectedJob]);
  // Function to handle job selection
  const handleJobSelection = (job) => {
    console.log(job, "This is job");
    setSelectedJob(job);
    onSelectJob(job);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "role",
        width:"10%",
        
        Cell: ({ row }) => (
          <>
            {
              <input
                type="radio"
                name="job"
                onClick={() => handleJobSelection(row.original)}
              />
            }
          </>
        ),
      },
      {
        Header: "Jobs",
        accessor: "title",
        width:"80%",
        Cell: AvatarCell,
        nameAccessor: "title",
        emailAccessor: "contactEmail",
      },
    ],
    []
  );

  const tabs = [
    {
      name: "tab1",
      label: "Generate Job Description",
      content: (
        <JobDescriptionForm
          setSelectedJob={setSelectedJob}
        ></JobDescriptionForm>
      ),
    },
    {
      name: "tab2",
      label: "Upload Job",
      content: <UploadJobWithDropzone setSelectedJob={handleJobSelection} />,
    },
  ];
  // JSX to render the list of jobs
  return (
    <div>
      {/* Modal */}
      {showModal && (
        <div className="fixed z-30 top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="modal-overlay fixed w-full h-full bg-gray-800 opacity-80"></div>
          <div className="modal z-50 bg-white rounded-lg overflow-hidden shadow-lg  ">
            {/* Modal header */}
            <div className="modal-header px-4 py-2 bg-gray-200 flex justify-between items-center">
              <h2 className="text-lg font-semibold">Add Job</h2>
              <button
                onClick={closeModal}
                className="text-gray-600 hover:text-gray-900 focus:outline-none"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body px-4 py-2 w-full">
              <OTabs tabs={tabs} />
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                onClick={handleCancel}
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
              <button
                onClick={handleOk}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {!isDataLoaded && <Loading />}

      {jobs && isDataLoaded && (
        <Table style={{height:'300px'}} 
          columns={columns}
          data={jobs}
          html={
            <>
              {" "}
              <button
                onClick={fetchJobs}
                className="bg-blue-500 z-1 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
              >
                <FontAwesomeIcon icon={faRefresh} />
              </button>{" "}
              <button
                onClick={openModal}
                className="bg-blue-500 z-1 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded nowrap"
              >
                 <FontAwesomeIcon icon={faPlus} /> Job
              </button>
            </>
          }
        />
      )}
    </div>
  );
}

export default JobsList;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AssessmentList = () => {
    const navigate = useNavigate();
  // Sample assessment data
  const assessments = [
    { name: 'Assessment 1', employee: 'John Doe', dateCreated: 'May 8, 2024', status: 'Completed' },
    { name: 'Assessment 2', employee: 'Jane Smith', dateCreated: 'May 7, 2024', status: 'In Progress' },
    // Add more assessment objects as needed
  ];

  // State for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 5; // Number of assessments per page
  const totalPages = Math.ceil(assessments.length / pageSize);

  // Function to render assessment list
  const renderAssessmentList = () => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, assessments.length);
    return assessments.slice(startIndex, endIndex).map((assessment, index) => (
      <tr key={index}>
        <td className="px-6 py-4 whitespace-nowrap">{assessment.name}</td>
        <td className="px-6 py-4 whitespace-nowrap">{assessment.employee}</td>
        <td className="px-6 py-4 whitespace-nowrap">{assessment.dateCreated}</td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span className={`bg-${assessment.status === 'Completed' ? 'green' : 'yellow'}-100 text-${assessment.status === 'Completed' ? 'green' : 'yellow'}-800 px-2 py-1 rounded`}>
            {assessment.status}
          </span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <a href="#" className="text-indigo-600 hover:text-indigo-900">Edit</a>
        </td>
      </tr>
    ));
  };

  // Function to handle pagination
  const handlePagination = (direction) => {
    if (direction === 'prev' && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else if (direction === 'next' && pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
 const handleCreateAssessment =() =>{
    navigate("/crewevaluator/createAssessment");
 }
 const handleListOfConcept = () => {
    navigate("/crewevaluator/listOfConcepts");
};
  return (
    <div className="container mx-auto py-8">
  
      <div className="flex justify-end">
  <div className="mb-4 mr-4">
    <button onClick={() => handleCreateAssessment()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">+ Assessment</button>
  </div>
  <div className="mb-4">
    <button onClick={() => handleListOfConcept()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">+ Concept</button>
  </div>
</div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
         <table  className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Assessment Name</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Created</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th scope="col" className="relative px-6 py-3"><span className="sr-only">Edit</span></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {renderAssessmentList()}
          </tbody>
        </table>
        <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
          <div className="hidden sm:block">
            <div className="flex justify-between items-center">
              <div>
                <span className="mr-2">Page</span>
                <span className="font-medium">{pageNumber}</span>
                <span className="mx-2">of</span>
                <span className="font-medium">{totalPages}</span>
              </div>
              <div>
                <button onClick={() => handlePagination('prev')} className="page-link" disabled={pageNumber === 1}>Previous</button>
                <button onClick={() => handlePagination('next')} className="page-link" disabled={pageNumber === totalPages}>Next</button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default AssessmentList;

// CandidateAnalysis.js
import React, { useEffect, useState } from "react";
import "./CandidateAnalysis.css"; // Make sure to create a corresponding CSS file
import { Pie } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import SkillExperienceChart from "../Candidate/SkillExperienceChart";
import PercentageOfFitChart from "../Candidate/PercentageOfFitChart";
import candidateService from "../../service/candidateService";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import AILoading from "../../../../components/AILoading";

function CandidateAnalysis() {
  const { getAccessTokenSilently } = useAuth0();
  const params = useParams();
  const [analysisData, setAnalysisData] = useState(null); // State to store analysis data
  const [isAnalysisDone,setIsAnalysisDone] = useState(false);
  useEffect(() => {
    fetchCandidateAnalyze();
  }, [params, getAccessTokenSilently]); // The empty dependency array ensures that this effect runs once on component mount
  const fetchCandidateAnalyze = async () => {
    const token = await getAccessTokenSilently();
    superagent.setToken(token);
    console.log(params);
    localStorage.setItem("selected", "");
    localStorage.setItem("number_question", 10);

    candidateService
      .analyzeCandidateSuitability(params.jobId, params.candidateId) // Replace with the actual job ID and candidate ID
      .then((response) => {
        setIsAnalysisDone(true)
        setAnalysisData(response);
      /*  if(response?.analysis){
          setAnalysisData(response?.analysis);
        }
        if(response?.analysis){
          setAnalysisData(response?.analysis?.data);
        }*/
      
      })
      .catch((error) => {
        console.error("Error analyzing candidate suitability:", error);
        setIsAnalysisDone(false)
      });
  };

  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setAnalysisData((prevAnalysisData) => ({
      ...prevAnalysisData,
      analysis: {
        ...prevAnalysisData.analysis,
        data: {
          ...prevAnalysisData.analysis.data,
          numOfQuestions: selectedValue,
        },
      },
    }));
  };
  const toggleSkillSelection = (skillName) => {
    const updatedSkills = analysisData?.analysis?.data?.descriptionSkills?.map(
      (skill) =>
        skill.name === skillName
          ? { ...skill, isSelected: !skill.isSelected }
          : skill
    );
    // Assuming analysisData is immutable, update the state with the new skills array
    setAnalysisData((prevAnalysisData) => ({
      ...prevAnalysisData,
      analysis: {
        ...prevAnalysisData.analysis,
        data: {
          ...prevAnalysisData.analysis.data,
          descriptionSkills: updatedSkills,
        },
      },
    }));
    console.log(
      "analysisData.descriptionSkills",
      analysisData?.analysis?.data?.descriptionSkills
    );
  };
  const toggleConceptsSelection = (conceptName) => {
    const updatedConcepts = analysisData?.analysis?.data?.concepts.map((concept) =>
      concept.name === conceptName
        ? { ...concept, isSelected: !concept.isSelected }
        : concept
    );
    // Assuming analysisData is immutable, update the state with the new skills array
    setAnalysisData((prevAnalysisData) => ({
      ...prevAnalysisData,
      analysis: {
        ...prevAnalysisData.analysis,
        data: {
          ...prevAnalysisData.analysis.data,
          concepts: updatedConcepts,
        },
      },
    }));
    console.log("analysisData.concepts", analysisData?.analysis?.data?.concepts);
  };
  const isAnySelected = () => {
    const isSkillSelected = analysisData?.analysis?.data?.descriptionSkills.some(
      (skill) => skill.isSelected
    );
    const isConceptSelected = analysisData?.analysis?.data?.concepts.some(
      (concept) => concept.isSelected
    );
    return isSkillSelected || isConceptSelected;
  };
  const handleGeneratQuestionClick = async () => {
    // Navigate to the CandidateAnalysis page
    const token = await getAccessTokenSilently();
    superagent.setToken(token);
    candidateService
      .updateAnalysisData(
        params.candidateId,
        params.jobId,
        analysisData?.analysis?.data
      ) // Replace with the actual job ID and candidate ID
      .then((response) => {
        console.log("analysisData_response", response);
        
      navigate(
        `/interviewgenius/questions/${params.jobId}/${params.candidateId}`
      );
     
      })
      .catch((error) => {
        console.error("Error analyzing candidate suitability:", error);
      });
  };
  const generateSkillBars = (data) => {
    if (
      !data ||
      !data?.labels ||
      !data?.data ||
      data?.labels.length !== data?.data?.length
    ) {
      return null; // Return null if data is invalid
    }

    const maxProgress = Math.max(...data?.data); // Get the maximum progress value
    const skillBars = data?.labels.map((label, index) => {
      const progress = data?.data[index];
      const percentage = (progress / maxProgress) * 100; // Calculate the percentage

      return (
        <div key={index} className="flex items-center mb-2 ">
          <div className="w-1/3 pr-2">{label}</div>
          <div className="w-1/2 pl-2">
            <div
              className="bg-blue-500 h-4 rounded"
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
          <div className="ml-2">{progress} Years</div>
        </div>
      );
    });

    return skillBars;
  };
  const handleBackButtonClick =()=>{
    navigate(`/interviewgenius/selectcandidate`);
  }
  return (
    <>{isAnalysisDone ?
    <div className="container mx-auto p-4 mb-20">
      {analysisData?.analysis && (
        <div className="grid gap-4">
          {/* Job Title and Candidate Name */}
          <div className="bg-white p-4 rounded-lg shadow-md mb-2 flex justify-between items-center">
          <div>
            <h2 className="text-lg font-semibold mb-2">
              Analysis for {analysisData.fullName}
            </h2>
            <p>Job Title: {analysisData.title}</p>
            </div>
            <button
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
    onClick={handleBackButtonClick}
  >
    Back To  Candidate Selection 
  </button>
          </div>

          {/* Analysis Charts and Details */}
          <div className="flex flex-wrap -mx-4">
            {/* Selected Skills */}
            <div
              className="bg-white p-4 m-2 rounded-lg shadow-md"
              style={{ width: "65%", maxHeight: "300px" }}
            >
              <h3 className="text-lg font-semibold mb-2">
                Selected skills to generate questions
              </h3>
              <div style={{ overflow: "auto", maxHeight: "220px" }}>
                {/* Render selected skills here */}
                <p>
                  {analysisData?.analysis?.data?.descriptionSkills.map(
                    (desc, index) =>
                      desc.isSelected && (
                        <span key={index} className="selectedTags">
                          {desc.name}
                        </span>
                      )
                  )}
                </p>
                <p>
                  {analysisData?.analysis?.data?.concepts.map(
                    (concept, index) =>
                      concept.isSelected && (
                        <span key={index} className="selectedTags">
                          {concept.name}
                        </span>
                      )
                  )}
                </p>

                {isAnySelected() ? (
                  <></>
                ) : (
                  <>
                    Please select at least one skill from the list below to
                    generate personalized questions.
                  </>
                )}
              </div>
            </div>

            {/* Get Questions */}
            <div
              className="bg-white p-4 m-2 rounded-lg shadow-md"
              style={{ width: "32%", maxHeight: "300px" }}
            >
              <h3 className="text-lg font-semibold mb-2"> Generate Question</h3>

              <>
                <select
                  className="number_question inline-block mr-4 border"
                  value={analysisData?.analysis?.data?.numOfQuestions}
                  onChange={handleSelectChange}
                >
                  <option selected value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
                <button
                  className={`user-queried-button inline-block font-bold py-2 px-4 rounded 
              ${
                isAnySelected()
                  ? "bg-blue-500 hover:bg-blue-600 text-white"
                  : "bg-gray-300 cursor-not-allowed text-gray-500"
              }`}
                  onClick={handleGeneratQuestionClick}
                  disabled={!isAnySelected()}
                >
                  Generate Question
                </button>
              </>
            </div>
            {/* Fit Chart */}
            <div
              className="bg-white p-4 m-2 rounded-lg shadow-md"
              style={{ width: "32%", maxHeight: "300px" }}
            >
              {/* Render PercentageOfFitChart here */}
              {analysisData?.analysis?.data?.fitChart && (
                <PercentageOfFitChart
                  percentage={analysisData?.analysis?.data?.fitChart}
                />
              )}
            </div>

            {/* Description Sections */}
            <div
              className="bg-white p-4 m-2 rounded-lg shadow-md"
              style={{ width: "32%", maxHeight: "300px" }}
            >
              <h3 className="text-lg font-semibold mb-2">Candidate Fit For</h3>
              <ul style={{ overflow: "auto", maxHeight: "220px" }}>
                {/* Map and render detail.fit here */}
                {analysisData?.analysis?.data?.fitChart?.detail?.fit?.map(
                  (desc, index) => (
                    <li key={index}>{desc}</li>
                  )
                )}
              </ul>
            </div>

            {/* Candidate Not Fit For */}
            <div
              className="bg-white p-4 m-2 rounded-lg shadow-md"
              style={{ width: "32%", maxHeight: "300px" }}
            >
              <h3 className="text-lg font-semibold mb-2">
              The candidate lacks knowledge in the following areas:
              </h3>
              <ul style={{ overflow: "auto", maxHeight: "220px" }}>
                {/* Map and render detail.notFit here */}
                {analysisData?.analysis?.data?.fitChart?.detail?.notFit?.map(
                  (desc, index) => (
                    <li key={index}>{desc}</li>
                  )
                )}
              </ul>
            </div>

            {/* Skill Experience Chart */}
            <div
              className="bg-white p-4 m-2 rounded-lg shadow-md"
              style={{ width: "32%", maxHeight: "300px" }}
            >
              {/* Render SkillExperienceChart here */}
              {analysisData?.analysis?.data?.skillExperienceChart && (
                <div className="max-w-md mx-auto mt-4" >
                  <div className="font-semibold">Skills Progress:</div>
                  <div className="grid gap-2" style={{ overflow: "auto", maxHeight: "220px" }}>
                    {generateSkillBars(
                      analysisData?.analysis?.data?.skillExperienceChart
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Matched Description & Skills */}
            <div
              className="bg-white p-4 m-2 pb-4 rounded-lg shadow-md"
              style={{ width: "32%", maxHeight: "300px" }}
            >
              <h3 className="text-lg font-semibold mb-2">
                Matched Description & Skills
              </h3>
              <ul style={{ overflow: "auto", maxHeight: "220px" }}>
                {/* Map and render descriptionSkills here */}
                {analysisData?.analysis?.data?.descriptionSkills.map(
                  (skill, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        name="skills"
                        value={skill.name}
                        checked={skill.isSelected}
                        onChange={() => toggleSkillSelection(skill.name)}
                        className="mr-2"
                      ></input>
                      {skill.name}
                    </li>
                  )
                )}
              </ul>
            </div>

            {/* Matched Concepts */}
            <div
              className="bg-white p-4 m-2 rounded-lg shadow-md"
              style={{ width: "32%", maxHeight: "300px" }}
            >
              <h3 className="text-lg font-semibold mb-2">Matched Concepts</h3>
              <ul style={{ overflow: "auto", maxHeight: "220px" }}>
                {/* Map and render concepts here */}
                {analysisData?.analysis?.data?.concepts.map(
                  (concept, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        name="concept"
                        value={concept.name}
                        checked={concept.isSelected}
                        onChange={() => toggleConceptsSelection(concept.name)}
                        className="mr-2"
                      ></input>
                      {concept.name}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div> : <AILoading text="WeEvaluate AI assesses candidate suitability & matches job skills for selection."/>
    }</>
  );
}

export default CandidateAnalysis;

import React, { createContext, useContext, useState } from 'react';

const UserPlanContext = createContext();

export const UserPlanProvider = ({ children }) => {
  const [userPlan, setUserPlan] = useState('basic'); // Example default value

  return (
    <UserPlanContext.Provider value={{ userPlan, setUserPlan }}>
      {children}
    </UserPlanContext.Provider>
  );
};

export const useUserPlan = () => useContext(UserPlanContext);

import React, { useState } from 'react';
import PDFViewerDialog from '../../components/PDFViewerDialog';
import superagent from "../../services/weevaluateagent";
import candidateService from "../../services/candidateService";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import EllipsisMenu from '../../components/EllipsisMenu';

const ApplicantCard = ({ applicant,job }) => {

    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    const [docURL, setDocURL] = useState(null);

    const fetchSignedURL = async (url) => {
        try {
            const token = await getAccessTokenSilently();
            superagent.setToken(token);
            const signedUrlResponse = await candidateService.signedURL(url);
            console.log(signedUrlResponse);
            return signedUrlResponse;
        } catch (error) {
            console.error('Error fetching signed URL:', error);
            return null;
        }
    };

    const openModal = async (url) => {
        try {
            const signedUrlResponse = await fetchSignedURL(url);
            if (signedUrlResponse) {
                setDocURL(signedUrlResponse.signedUrl);
                setIsOpen(true);
            } else {
                console.error('Failed to fetch signed URL');
            }
        } catch (error) {
            console.error('Error opening modal:', error);
        }
    };

    const closeModal = () => setIsOpen(false);
    const handleAnalysisClick = (jobId, candidateId) => {
        // Navigate to the CandidateAnalysis component with jobId and candidateId
     
        navigate(`/interviewgenius/analysis/${jobId}/${candidateId}`);
      };
      const getTopSkills = () => {
        // Filter skills by availability and sort by availability level
        const filteredSkills = applicant.skills.filter(skill => skill.availability.toLowerCase() === 'expert' || skill.availability.toLowerCase() === 'advanced')
                                              .sort((a, b) => {
                                                  // Adjust sorting logic as per availability levels
                                                  if (a.availability.toLowerCase() === 'expert' && b.availability.toLowerCase() === 'advanced') return -1;
                                                  if (a.availability.toLowerCase() === 'advanced' && b.availability.toLowerCase() === 'expert') return 1;
                                                  return 0;
                                              });

        // Get top 5 skills or all skills if less than 5
        const topSkills = filteredSkills.slice(0, 5).map(skill => skill.skill);
        return topSkills.join(', ');
    };
    return (
        <div className="bg-white shadow-md rounded-lg p-4 m-2 w-90 relative">
        {applicant?.resumeURL?.location && docURL && (
            <PDFViewerDialog isOpen={isOpen} onClose={closeModal} pdfUrl={docURL} />
        )}
        <div className="mb-10">
            <div className="flex justify-between items-center mb-2">
                <h2 className="text-xl font-semibold">{applicant.fullName}</h2>
                <EllipsisMenu />
            </div>
            <div className="flex flex-col mb-5 h-screen-1/3">
                <div className="text-gray-600 mb-2">
                    <p><span className="font-semibold">Overall Experience:</span> {applicant.overAllExp}</p>
                </div>
                <div className="text-gray-600 mb-2">
                    <p><span className="font-semibold">Top Skills:</span> {getTopSkills()}</p>
                </div>
            </div>
            <p className="text-gray-600 mb-2">Email: {applicant.email}</p>
            <p className="text-gray-600 mb-4">Phone: {applicant.phone}</p>
            {/* Additional candidate information */}
            {/* Example:
            <p className="text-gray-600 mb-2">Email: {applicant.email}</p>
            <p className="text-gray-600 mb-2">Phone: {applicant.phone}</p>
            <p className="text-gray-600 mb-2">LinkedIn: {applicant.linkedin}</p>
            <p className="text-gray-600 mb-2">GitHub: {applicant.github}</p>
            */}
        </div>
        <div className="absolute bottom-0 left-0 w-full ">
            <div className="flex justify-between m-2 mt-6">
                {applicant?.resumeURL?.location && (
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-full m-1 flex-1" onClick={() => openModal(applicant?.resumeURL?.location)}>View Resume</button>
                )}
                <button className="bg-purple-500 text-white px-4 py-2 rounded-full m-1 flex-1" onClick={() => handleAnalysisClick(job._id, applicant._id)}>Analyse</button>
            </div>
        </div>
    </div>

    );
};

export default ApplicantCard;

import React from 'react';
import { PlusSmIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import InlineButtonControl from './InlineButtonControl';
const Education = ({ education, isExisted, isEditing, isEduDetailsEdit, handleChange, deleteEducation, addEducation, eduDetailsSave, eduDetailsEdit }) => {
  return (
    <div>
      <div className="flex justify-end items-center mt-2">
    {isEditing && (
      <div className=" cursor-pointer" onClick={addEducation}>
        <PlusSmIcon className="w-8 h-8 text-blue-500 mr-2" />
      </div>
    )}
    <div>
      {isExisted && <InlineButtonControl saveClick={eduDetailsSave} editClick={eduDetailsEdit} />}
    </div>
  </div>
  <div>
    {education.map((edu, index) => (
      <div key={index} className="mb-4 flex flex-row border rounded py-2 px-4">
        <div className="grid grid-cols-3 gap-4 w-full">
          <div>
            {index === 0 && (<label htmlFor={`degree_${index}`} className="block text-gray-700 font-bold mb-2  leftside_text">Qualification</label>)}
            {!isExisted || isEduDetailsEdit ? (
              <input 
                type="text" 
                id={`degree_${index}`} 
                name={`degree_${index}`}
                value={edu.degree}
                onChange={(e) => handleChange(e, index)} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Qualification" 
              />
            ) : (
              <div className='data-value'>{edu.degree}</div>
            )}
          </div>
          <div>
            {index === 0 && (<label htmlFor={`institution_${index}`} className="block text-gray-700 font-bold mb-2  leftside_text">Institution</label>)}
            {!isExisted || isEduDetailsEdit ? (
              <input 
                type="text" 
                id={`institution_${index}`}
                name={`institution_${index}`}
                value={edu.institution}
                onChange={(e) => handleChange(e, index)} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Institution"
              />
            ) : (
              <div className='data-value'>{edu.institution}</div>
            )}
          </div> 
          <div>
            {index === 0 && (<label htmlFor={`graduationDate_${index}`} className="block text-gray-700 font-bold mb-2  leftside_text">Graduation Date</label>)}
            {!isExisted || isEduDetailsEdit ? (
              <input 
                type="date"
                id={`graduationDate_${index}`}
                name={`graduationDate_${index}`}
                value={edu.graduationDate}
                onChange={(e) => handleChange(e, index)} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              />
            ) : (
              <div className='data-value'>{edu.graduationDate}</div>
            )}
          </div> 
        </div>
        <div className='ml-3'>
        {index===0 && (<label htmlFor={`graduationDate_${index}`} className="block text-gray-700 font-bold mb-2 leftside_text">&nbsp;</label>)}
          {isExisted && isEduDetailsEdit && (
            <FontAwesomeIcon icon={faTrash} onClick={() => deleteEducation(index)} className="text-red-500" />
          )}
        </div>
      </div>
    ))}
    </div>
    </div>
  );
};

export default Education;

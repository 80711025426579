import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import candidateService from "../../service/candidateService";
import AILoading from "../../../../components/AILoading";
import QuestionDetailsTab from "./QuestionDetailsTab";
import { WeEvaluateCameraRecordings } from "../WeEvaluateCameraRecordings";

function InterviewReport() {
  const [selectedQuestion, setSelectedQuestion] = useState({});
  
  const { user, getAccessTokenSilently } = useAuth0();
  const [interviewData, setInterviewData] = useState();
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const [questionsData, setQuestionsData] = useState();
  const [isReportLoading, setIsReportLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    fetchInterviewData();
  }, [params, getAccessTokenSilently]);
  const fetchInterviewData = async () => {
    const token = await getAccessTokenSilently();
    superagent.setToken(token);

    candidateService
      .interviewValidation(params.jobId, params.candidateId) // Replace with the actual job ID and candidate ID
      .then((response) => {
        console.log(response.error);

        setIsReportLoading(false);
        setInterviewData(response);
        setQuestionsData(removeUndefinedQid(response?.interview?.finalQuestionList));
        if(response?.interview?.finalQuestionList[0])
        setSelectedQuestion(response?.interview?.finalQuestionList[0])
        const hasInterviewCompleted = response?.interview?.status?.some(
          (item) => item.status === "Interview Completed"
        );
        if (hasInterviewCompleted) {
          setShowGenerateButton(hasInterviewCompleted);
        }
      })
      .catch((error) => {
        console.error("Error in Fetching InterviewData:", error);
      });
  };
  const removeUndefinedQid = (data) => {
    return data.filter(item => item.qid !== "undefined");
  };
  const handleSelectQuestion = (question) => {
    setSelectedQuestion(question);
  };

  const handleGetFinalReport = () => {
    navigate(`/interviewgenius/finalreport/${params.jobId}/${params.candidateId}`);
}
  if (isReportLoading) {
    return (
      <AILoading text="WeEvaluate AI is currently validating the candidate interview. Please wait a moment." />
    );
  }

  return (
    <div className="container mx-auto p-5 mb-20">
      <div className="mb-4 flex justify-end">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleGetFinalReport()}>
          Final Report
        </button>
      </div>

      <div className="w-full  mb-6">
        <section className="bg-white rounded-lg shadow p-6">
          <div className="flex mt-4">
            <div className="w-1/2 pr-2">
              <p>
                <strong>Name : </strong>
                {interviewData?.fullName}
              </p>
              <p>
                <strong>Email:</strong> {interviewData?.email}
              </p>
            </div>
            <div className="w-1/2 pl-2">
              <p>
                <strong>Phone:</strong>  {interviewData?.phone}
              </p>
              <p>
                <strong>Position:</strong> {interviewData?.title}
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="flex space-x-5">
        <div className="w-1/3 bg-white  rounded shadow ">
          <h3 className="text-lg font-semibold mb-3 m-2">{questionsData?.length} Interview Questions </h3>
          <ul className=" overflow-auto h-80 overflow-auto">
            {questionsData?.map((question, index) => (
             question.qid && <li
                key={index}
                className={`cursor-pointer p-2 border rounded mb-1 ${
                  selectedQuestion && selectedQuestion.qid!=="undefined" && selectedQuestion.qid === question.qid
                    ? "bg-blue-500 text-white"
                    : "hover:bg-gray-200"
                }`}
                onClick={() => handleSelectQuestion(question)}
              >
                
                <span className="truncate max-w-40 inline-block pl-4 py-1 "><b className="text-lg font-semibold mb-3 m-2">{index+1}</b>&nbsp;{question?.question?.length > 40 ? `${question?.question?.substring(0, 40)}...` : question}</span>
  
              </li>
            ))}
          </ul>
        </div>
        <div className="w-2/3 bg-white  rounded shadow overflow-auto h-100 ">
          <QuestionDetailsTab selectedQuestion={selectedQuestion} />
        </div>

        <div className="w-2/3 bg-white p-5 rounded shadow overflow-auto h-100 ">
          {interviewData?.interview?.mediaPipelineId && <WeEvaluateCameraRecordings candidateMediaPipelineId={interviewData?.interview?.mediaPipelineId}/>}
        </div>
      </div>
    </div>
  );
}

export default InterviewReport;

import axios from 'axios';
import weevaluateagent from './weevaluateagent';

const API_BASE_URL = '/chatbot/v1/chat'; // Replace with your API base URL

const chatBotService = {
 
  // Create a new job
  createChat: async (chatData) => {
    try {
      console.log("in chatBotService", chatData)
      
      const response = await weevaluateagent.requestsA.post(`${API_BASE_URL}/send`, chatData);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },
};

export default chatBotService;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusSmIcon } from '@heroicons/react/outline';
import superagent from "../../services/weevaluateagent";
import jobService from '../../services/jobService';
import EllipsisMenu from '../../components/EllipsisMenu';
import { useAuth0 } from '@auth0/auth0-react';
import Table from '../../../../components/OTable/Table'; // new
import JobList from './JobList';

function JobInfo() {
  const { getAccessTokenSilently,user } = useAuth0();
  const [formData, setFormData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [statusValues, setStatusValues] = useState({});

  const navigate = useNavigate();

  const gotoJobForm = () => {
    navigate("/ats/job");
  };

  useEffect(() => {
    fetchData();
   
  }, []);

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await jobService.getAllJobs();
       
      response.forEach(entry => handleStatusUpdate(entry._id));
      setFormData(response); 
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const handleDelete = async () => {
    if (deleteIndex !== null && deleteIndex !== undefined) {
      try {
        const token = await getAccessTokenSilently();
        superagent.setToken(token);
        await jobService.deleteJobById(formData[deleteIndex]._id);
        const updatedData = formData.filter((_, index) => index !== deleteIndex);
        setFormData(updatedData);
        setShowDialog(false);
      } catch (error) {
        console.error('Error deleting job:', error);
      }
    }
  };



  const handleConfirmDelete = () => {
    handleDelete();
  };

  const handleCancelDelete = () => {
    setShowDialog(false);
  };
  
  const handleStatusUpdate = (entryId, status) => {
    setStatusValues(prevState => ({
      ...prevState,
      [entryId]: status
    }));
  };
 
  const columns = React.useMemo(() => [
    {
      Header: "Job Title",
      accessor: 'title',
      width:'12%'
      
    },
  
    {
      Header: "Positions",
      accessor: 'noOfPositions',
      width:'8%'
     
    },
    {
      Header: "Experience",
      accessor: 'experience',
      width:'8%'
     
    },
    
    
    {
      Header: "Status",
      accessor: 'status',
      Cell: ({ row }) => (
        <div style={{ color: 'black' }}>
          {row.original.statusUpdates?.length > 0 ? row.original.statusUpdates[row.original.statusUpdates?.length - 1].status : 'Profile Created'}
        </div>
      )
    },
    {
      Header: "Latest History",
      accessor: 'history',
      Cell: ({ row }) => (
        <div style={{ color: 'black' }}>
          {row.original.historyUpdates?.length > 0 ? row.original.historyUpdates[row.original.historyUpdates?.length - 1].history : 'Profile Created'}
        </div>
      )
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div style={{position:'absolute'}}>
          {/* EllipsisMenu component with proper props */}
          <EllipsisMenu
            isOpen={true} // Set isOpen to true for testing
           job={row.original}
            entryId={row.original._id}
            onDelete={() => { setShowDialog(true); setDeleteIndex(row.index) }}
            onStatusUpdate={handleStatusUpdate}
          />
        </div>
      ),
    },
  ], []);
  
  
  return (
    <div>
      {showDialog && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-m322222um text-gray-900">Confirm Delete</h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Are you sure you want to delete this record?</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button onClick={handleConfirmDelete} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                  OK
                </button>
                <button onClick={handleCancelDelete} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                  Cancel
                </button>
      </div>
            </div>
          </div>
        </div>
      )}
      <div className="container mx-auto py-2">
        <div className=" mb-3 flex flex-row justify-end">
        <button onClick={() => gotoJobForm()}  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Add Job</button>
       
        </div>
        <div className="overflow-x-auto h-full">
         {/*<Table  style={{height:'300px'}} columns={columns} data={formData} />*/}
         <JobList jobData={formData}/>
        </div>
      </div>
    </div>
  );
}

export default JobInfo;

// InterviewGeniusRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import JobForm from './pages/Job/JobForm';
import CandidateForm from './pages/Candidate/CandidateForm';
import CandidateInfo from './pages/Candidate/CandidateInfo';
import UserProfileForm from './pages/Profile/UserProfileForm';
import TeamMemberForm from './pages/Team/Team';
import JobInfo from "./pages/Job/JobInfo";
import Applicants from "./pages/Job/Applicants";


const ATSRoutes = () => {
  return (
    <Routes>
      
        <Route path="/candidate" Component={CandidateForm} />

        <Route path="/candidate/:id" Component={CandidateForm } />
        <Route path="/job" element={<JobForm/>} />
        <Route path="/job/:id" element={<JobForm/>} />
        <Route path="/applicants/:jobId" element={<Applicants/>} />
        <Route path="/jobInfo" element={<JobInfo/>} />
        <Route path="/candidateInfo" Component={CandidateInfo} />
        <Route path="/team" Component={TeamMemberForm} />
        <Route path="/profile" Component={UserProfileForm} /> 

    </Routes>
  );
};

export default ATSRoutes;

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LandingScreen from "./components/LandingScreen";
import HomeScreen from "./components/HomeScreen";
import Loading from "./components/Loading"; // Make sure to have a Loading component
import Dashboard from "./modules/Dashboard";
import InterviewGeniusRoutes from "./modules/InterviewGenius";
import ErrorBoundary from "./components/ErrorBoundary";
import ATSRoutes from "./modules/ApplicationTrackingSystem";
import superagent from "./modules/ApplicationTrackingSystem/services/weevaluateagent";
import userService from "./modules/ApplicationTrackingSystem/services/userService";
import UserProfileForm from "./modules/ApplicationTrackingSystem/pages/Profile/UserProfileForm";
import LogoutButton from "./components/LogoutButton";
import EmailVerificationMessage from "./components/EmailVerificationMessage";
import CrewEvaluatorRoutes from "./modules/CrewEvaluator";

function App() {
  const { isLoading, isAuthenticated, user, error, getAccessTokenSilently } =
    useAuth0();
  const [companyProfileAvailable, setCompanyProfileAvailable] = useState(false); // Add state for company profile availability
  const [companyStatus, setCompanyStatus] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [client, setClient] = useState();
  const isCompanyProfileAvailable = async (email) => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      console.log("token", token);
      const response = await userService.checkCompanyProfileEmail(email);
      const data = response;
      setIsVerified(true);
      console.log("data", data);
      //console.log('nvjbkhaas',data.companyName)
      if (data.isClientAvailable) {
        setCompanyProfileAvailable(data.isClientAvailable);
        setCompanyStatus(data.isComapnyActive);
        setClient(data.client); // Update state based on response
        console.log("isComapnyActive", data.isComapnyActive);
      }
    } catch (error) {
      console.error("Error checking short name availability:", error);
    }
  };
  useEffect(() => {
    if (user && user.email_verified) {
      setIsEmailVerified(true);
      isCompanyProfileAvailable(user.email);
    }
  }, [isLoading]);
  if (isLoading || !isEmailVerified) {
    console.log('isEmailVerified',isEmailVerified)
    console.log('isLoading',isLoading)
    console.log('isAuthenticated',isAuthenticated)
    if (isLoading) return <Loading />;
    if (isAuthenticated && !isEmailVerified) return <EmailVerificationMessage />;
  }
  

  return (
    <ErrorBoundary>
      {!isCompanyProfileAvailable && !isVerified && <LogoutButton />}
      {isLoading && <> Loading..</>}
      {!isLoading && (
        <Router>
          <Routes>
            {" "}
            {/* Ensure Routes wraps all Route components */}
            {!isAuthenticated && <Route path="/" element={<LandingScreen />} />}
            {isAuthenticated && (
              <>
                {companyProfileAvailable && companyStatus ? (
                  <Route path="/" element={<HomeScreen sidebar={true} />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                      path="interviewgenius/*"
                      element={<InterviewGeniusRoutes />}
                    />
                     <Route
                      path="crewevaluator/*"
                      element={<CrewEvaluatorRoutes/>}
                    />
                    <Route path="ats/*" element={<ATSRoutes />} />
                  </Route>
                ) : (
                  isVerified && (
                    <Route path="/" element={<HomeScreen sidebar={false} />}>
                      <Route
                        path="/"
                        element={
                          <UserProfileForm
                            profile={client}
                            companyStatus={companyStatus}
                            callbackCompanyProfileAvailable={isCompanyProfileAvailable}
                          />
                        }
                      />{" "}
                    </Route>
                  )
                )}
              </>
            )}
            {/* Fallback or default route  <Route path="*" element={<LandingScreen />} /> */}
          </Routes>
        </Router>
      )}
    </ErrorBoundary>
  );
}

export default App;

import React, { useState } from 'react';

function HelpSection({ steps }) {
  const [openTab, setOpenTab] = useState(null);

  const toggleTab = (index) => {
    setOpenTab((prevOpenTab) => (prevOpenTab === index ? null : index));
  };

  return (
    <div className="border-t border-gray-200">
      {steps.map((step, index) => (
        <div key={index} className={`px-4 py-5 ${index % 2 ? 'bg-white' : 'bg-gray-50'}`}>
          <dt className="text-sm font-medium text-gray-500 flex justify-between items-center cursor-pointer" onClick={() => toggleTab(index)}>
            {step.title}
            <span>{openTab === index ? '-' : '+'}</span>
          </dt>
          {openTab === index && (
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
              {step.details}
            </dd>
          )}
        </div>
      ))}
    </div>
  );
}

export default HelpSection;
